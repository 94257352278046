<template>
  <div class="album">
    <div class="album-image ">
      <img
        :src="ImageUrl"
        alt=""
      >
      <div class="overlay">
        <div
          class="action"
          @click="$emit('clickAlbumDialog','edit',id)"
        >
          Edit
        </div>
        <div class="action">
          <router-link :to="'/create-gallery/'+id">
            Open
          </router-link>
        </div>
      </div> 
    </div>
      
    <div class="album-name">
      <v-tooltip
        v-if="albumName.length > 8"
        :text="albumName"
      >
        <template #activator="{ props }">
          <span
            v-bind="props"
          >
            {{ truncateString(albumName, 10) }} 
          </span>
        </template> 
      </v-tooltip>
      <span v-else>
        {{ truncateString(albumName, 10) }} 
      </span>
    </div>
  </div>
</template>

<script setup>
import {ref, onBeforeMount} from 'vue'
import { useStore} from 'vuex';
import { truncateString } from '@/helper/index.js';
import { getImage } from '@/service/helper-service.js';

const store = useStore(); 
const props = defineProps({
	id:{
		type:String,
		default:''
	},
	albumName:{
		type:String,
		default:''
	},
	coverPicture:{
		type:String,
		default:''
	}
})

defineEmits(['clickAlbumDialog']);

const ImageUrl = ref(require(`@/assets/images/thumbnail.png`));

onBeforeMount(()=>{
	if(props.coverPicture){
		loadImage(props.coverPicture)
	}
})

const loadImage = async(fileName)=>{
	try {
		const response = await getImage(`/files?name=${fileName}`);
		if(response.status === 200){
			ImageUrl.value = 'data:image/jpeg;base64,'+response?.data?.data;
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}  
	
}


</script>

<style>
.album{box-shadow: 0px 4px 16px 2px #A3A3A340;padding:5px;border-radius: 8px;margin:9px 7px 12px;flex-basis: 18.53%;height: max-content;}
.album-image{position: relative;width: 100%;height: 125px;}
.album-image img{width: 100%;height: 125px;object-fit: cover;border-radius: 6px;position: relative;}
.overlay{position: absolute;
    background-color: #242426b0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
  border-radius: 6px;
  transition: .3s ease-in-out;
 
}
.overlay .action{text-align: center;
    background-color: white;
    min-width: 80px;
    display: block;
    margin: 8px auto 3px;
    border-radius: 4px;
    padding: 4px 19px;cursor: pointer;}
    .overlay .action:nth-child(2){padding:unset;}
    .overlay .action:nth-child(2) a{text-decoration: none;color:black;padding: 4px 19px;display: block;}
    .album-image:hover .overlay{display: flex;opacity: 1;}   
.album-name{text-align: center;font-weight: 700;padding: 4px 0;}
.v-skeleton-loader-img{background-color: gainsboro;overflow: hidden;}
.v-skeleton-loader-imge::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    animation: loading 1.5s infinite;
    background: linear-gradient(90deg, rgba(var(--v-theme-surface), 0), rgba(var(--v-theme-surface), 0.3), rgba(var(--v-theme-surface), 0));
    transform: translateX(-100%);
    z-index: 1;
}


</style>