<template>
  <div class="v-container">
    <h3>{{ formHeading }}</h3>
  </div>
  <div class="form-card">
    <div class="v-container">
      <Form
        ref="form"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="form-row">
          <div class="form-profile">
            <div class="profile-container">
              <div
                v-show="imageUrl!=''"
                class="remove-profile"
                @click="removeProfile"
              >
                <fa icon="trash" />
              </div>
              <div class="profile-image">
                <img
                  :src="imageUrl?imageUrl:require(`@/assets/images/avatar.svg`)"
                  alt="profile"
                  accept="image/png, image/jpeg"
                >
             
                <label
                  for="upload_profile"
                  :class="loader?'progress-bar':''"
                ><fa icon="camera" /></label>
                <Field
                  id="upload_profile"
                  name="upload_profile"
                  type="file"
                  style="display: none;"
                  @change="profileUpload"
                />
              </div>
            </div>
            <div
              class="profile-error  error-msg"
            />
            <div style="font-size: 12px;color:gray;margin-top: 12px;">
              Maximum upload file size 5 MB
            </div>
            <ErrorMessage name="upload_profile" />
            <!-- hidden input for validation purpose in yup schema-->
            <Field
              v-model="uploadedProfile"
              name="uploadedProfile"
              type="hidden"
            />
            <ErrorMessage name="uploadedProfile" /> 
          </div>
          
          <div class="basic-information">
            <div class="form-row">
              <div class="form-col-half title">
                <label for="title">Title<span>*</span></label>

                <Field
                  v-model="title"
                  name="title"
                  as="select"
                >
                  <option
                 
                    value="Adv"
                  >
                    Adv
                  </option>
                </Field>
      
                <ErrorMessage name="title" />
              </div>

              <div class="form-col-half">
                <label for="firstName">First Name<span>*</span></label>
                <Field
                  v-model.trim="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                />
                <ErrorMessage name="firstName" /> 
                <span />
              </div>
              <div class="form-col-half">
                <label for="middleName">Middle Name</label>
                <Field
                  v-model.trim="middleName"
                  name="middleName"
                  type="text"
                  placeholder="Middle Name"
                />
                <ErrorMessage name="middleName" /> 
              </div>

              <div class="form-col-half">
                <label for="lastName">Last Name<span>*</span></label>
                <Field
                  v-model.trim="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                />
                <ErrorMessage name="lastName" /> 
              </div>
            </div>



            <div class="form-row">
              <div class="form-col-half">
                <label for="dob">Date of Birth<span>*</span></label>
                <div class="input-group">
                  <Field
                    id="dob"
                    v-slot="{field}"
                    v-model="dob"
                    name="dob"
                  >
                    <flat-pickr
                              
                      v-bind="field"
                      ref="datePickerWrap"
                      v-model="field.value"
                      :config="dobConfig"
                      
                      class="date-form-control"
                      placeholder="Date of Birth"
                    />
                    <div class="input-group-append">
                      <button
                        type="button"
                        colorpicker="colorpickere"
                        data-toggle
                      >
                        <fa icon="calendar" />
                        <span
                          aria-hidden="true"
                          class="sr-only"
                        >Toggle</span>
                      </button>
                    </div>
                  </Field>
                </div>
                <ErrorMessage name="dob" />
              <!-- <Calendar /> -->
              </div>

              <div class="form-col-half gender">
                <label for="gender">Gender<span>*</span></label>

                <Field
                  v-model="gender"
                  name="gender"
                  as="select"
                >
                  <option
                    value=""
                  >
                    Gender
                  </option>
                  <option value="Male">
                    Male
                  </option>
                  <option value="Female">
                    Female
                  </option>
                  <option value="Other">
                    Other
                  </option>
                </Field>
      
                <ErrorMessage name="gender" />
              </div>

              <div class="form-col-half blood-group">
                <label for="bloodGroup">Blood Group</label>

                <Field
                  v-model="bloodGroup"
                  name="bloodGroup"
                  as="select"
                >
                  <option
                    value=""
                  >
                    Blood Group
                  </option>
                  <option value="A+">
                    A+
                  </option>
                  <option value="A-">
                    A-
                  </option>
                  <option value="B+">
                    B+
                  </option>
                  <option value="B-">
                    B-
                  </option>
                  <option value="AB+">
                    AB+
                  </option>
                  <option value="AB-">
                    AB-
                  </option>
                  <option value="O+">
                    O+
                  </option>
                  <option value="O-">
                    O-
                  </option>
                </Field>
      
                <ErrorMessage name="bloodGroup" />
              </div>
            </div>
          </div>
        </div>

        

        <div class="form-row">
          <div class="form-col-half">
            <label for="email">Email<span>*</span></label> 

            <Field
              v-model.trim="email"
              name="email"
              type="text"
              style="text-transform:unset"
              placeholder="Email"
            />
            <ErrorMessage name="email" />
          </div>

          <div class="form-col-half">
            <label for="phoneNumber">Phone Number</label>
     

            <Field
              id="phoneNumber"
              v-slot="{field}"
              v-model="phoneNumber"
              name="phoneNumber"
              type="tel"
              @input="phoneNumberChanged"
              @blur="phoneNumberChanged"
            >
              <vue-tel-input
                v-bind="field"
                v-model.trim="field.value"
                :input-options="options"
                @country-changed="countryChanged"
              /> 
            </Field>
            <span>    
              <ErrorMessage
                v-if="isPhoneNumberTouched"
                name="phoneNumber"
              />
            </span>
          </div>    

          <div class="form-col-half">
            <label for="enrollmentNo">Enrollment No<span>*</span></label>
            <Field
              v-model.trim="enrollmentNo"
              name="enrollmentNo"
              type="text"
              placeholder="Enrollment No"
            />
            <ErrorMessage name="enrollmentNo" /> 
            <span />
          </div>

          
          
          <div class="form-col-half doj-col">
            <label for="doj">Date of Joining<span>*</span></label>
            <div class="input-group">
              <Field
                id="doj"
                v-slot="{field}"
                v-model="doj"
                name="doj"
              >
                <flat-pickr
                              
                  v-bind="field"
                  v-model="field.value"
                  :config="dojConfig"
                  class="date-form-control"
                  placeholder="Date of Joining"
                  name="doj"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    colorpicker="colorpickere"
                    data-toggle
                  >
                    <fa icon="calendar" />
                    <span
                      aria-hidden="true"
                      class="sr-only"
                    >Toggle</span>
                  </button>
                </div>
              </Field>
            </div>
            <ErrorMessage name="doj" />
          <!-- <Calendar /> -->
          </div>
        </div>



       
        <div
          class="form-row"
          style="margin-top:5px;"
        >
          <div class="form-col-half">
            <h3 style="margin-bottom:5px;">
              Office Address
            </h3> 
            <div class="form-row">
              <div class="form-col-full">
                <label for="addressLineOne">Address Line 1</label>
                <Field
                  v-model="addressLineOne"
                  name="addressLineOne"
                  type="text"
                  placeholder="Address Line 1"
                />
                <ErrorMessage name="addressLineOne" /> 
                <span />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col-full">
                <label for="addressLineTwo">Address Line 2</label>
                <Field
                  v-model="addressLineTwo"
                  name="addressLineTwo"
                  type="text"
                  placeholder="Address Line 2"
                />
                <ErrorMessage name="addressLineTwo" /> 
                <span />
              </div>
            </div>

            <div class="form-row">
              <div class="form-col-half">
                <label for="district">District</label>
                <Field
                  id="district"
                   
                  v-model="district"
                  name="district"
                  as="select"
                >
                  <option
                    value=""
                  >
                    Select District
                  </option>

                  <option
                    v-for="item in statesData.states[29].districts"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </Field>
      
                <ErrorMessage name="district" />
              </div>

              <div class="form-col-half">
                <label for="pincode">Pincode</label>
                <Field
                  v-model.trim="pincode"
                  name="pincode"
                  type="text"
                  placeholder="Pincode"
                />
                <ErrorMessage name="pincode" /> 
                <span />
              </div>
            </div>
          </div>
          <div class="form-col-half">
            <h3 style="margin-bottom:5px;">
              Residental Address
            </h3> 
            <div class="form-col-full">
              <label for="residentalAddressLineOne">Address Line 1</label>
              <Field
                v-model="residentalAddressLineOne"
                name="residentalAddressLineOne"
                type="text"
                placeholder="Address Line 1"
              />
              <ErrorMessage name="residentalAddressLineOne" /> 
              <span />
            </div>

            <div class="form-col-full">
              <label for="residentalAddressLineTwo">Address Line 2</label>
              <Field
                v-model="residentalAddressLineTwo"
                name="residentalAddressLineTwo"
                type="text"
                placeholder="Address Line 2"
              />
              <ErrorMessage name="residentalAddressLineTwo" /> 
              <span />
            </div>

            <div class="form-row">
              <div class="form-col-half">
                <label for="residentalDistrict">District</label>
                <Field
                  id="residentalDistrict"
                   
                  v-model="residentalDistrict"
                  name="residentalDistrict"
                  as="select"
                >
                  <option
                    value=""
                  >
                    Select District
                  </option>

                  <option
                    v-for="item in statesData.states[29].districts"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </Field>
      
                <ErrorMessage name="residentalDistrict" />
              </div>

              <div class="form-col-half">
                <label for="residentalPincode">Pincode</label>
                <Field
                  v-model.trim="residentalPincode"
                  name="residentalPincode"
                  type="text"
                  placeholder="Pincode"
                />
                <ErrorMessage name="residentalPincode" /> 
                <span />
              </div>
            </div>
          </div>
        </div>  


    

      

     

        <div class="form-row">
          <div
            class="form-col-full"
            style="margin-right: unset;"
          >
            <div class="form-actions">
              <button
                v-show="!memberId"
                type="button"
                @click="resetForm"
              >
                Reset
              </button>
              <button
                v-show="memberId"
                type="button"
                @click="cancelUpdate"
              >
                Cancel
              </button>
              <button
                type="submit"
                :disabled="saveMember?true:false"
                @click="phoneNumberChanged"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>





<script setup>
import { useStore} from 'vuex';
import { useRoute,useRouter } from 'vue-router';  
import { isValidPhoneNumber} from "libphonenumber-js/mobile"; 
import {ref,onMounted} from 'vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import  statesData from '../../states-and-districts.json';

import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import { reactive } from 'vue';
import { capitalizeString } from '@/helper/index.js';
import {createMember,deleteProfile,uploadImage} from '@/service/helper-service.js';


const route = useRoute();
const router = useRouter();
const store = useStore(); 


const form  = ref();
const dialCode = ref('');

const loader = ref(false);
const imageUrl = ref('');
const uploadedProfile = ref('');
const profileImageId = ref('');
const title = ref('Adv');
const firstName = ref('');
const middleName = ref('');
const lastName = ref('');
const dob = ref('');
const gender = ref('');
const bloodGroup = ref('');
const phoneNumber =ref('');
const email = ref('');

const addressLineOne = ref('');
const addressLineTwo = ref('');
const district = ref('Kanyakumari');
const pincode = ref('');
const residentalAddressLineOne = ref('');
const residentalAddressLineTwo = ref('');
const residentalDistrict = ref('Kanyakumari');
const residentalPincode =ref('');






const enrollmentNo =ref('');
const typeOfAdvocate = ref('');
const fax = ref('');
const certificateNo = ref('');
const vakalat = ref('');
const firmRegistration = ref('');
const doj = ref('');
const saveMember = ref(false);


const authToken = ref(store.state.authentication.token);
const memberId = ref(route.params.id);
const formHeading = ref('');


const isPhoneNumberTouched = ref(false);
const isDobTouched = ref(false);

const phoneNumberChanged = () => {
	isPhoneNumberTouched.value = true;
}

const dobChanged = () => {
	isDobTouched.value = true;
}

const datePickerWrap = ref(null);

const options = ref({ placeholder: "Phone Number" })

const setMaxDate = ()=>{
	const currentDate = new Date();
	const result = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
	let dateParts = result.toISOString().split('T');
	return dateParts[0];
}

const dobConfig = reactive({
	wrap: true, // set wrap to true only when using 'input-group'
	altInput: true,
	altFormat: "j-M-Y",
	dateFormat: "Y-m-d",
	allowInput: false,
	disableMobile: true,  
	maxDate: setMaxDate() 
});

const dojConfig = ref({
	wrap: true, // set wrap to true only when using 'input-group'
	altInput: true,
	altFormat: "j-M-Y",
	dateFormat: "Y-m-d",
	disableMobile: true,  
	maxDate: new Date()
});




const schema = yup.object({
	upload_profile:yup.mixed().when('uploadedProfile',{
		is: (val) => val == '',
		then: (schema) => schema.required('Please upload Profile Image')
			.test(
				'fileType',
				'Invalid file extension. Please select a JPEG or PNG file',
				(value) => {
					console.log("value",value);
					console.log("value.type",value.type);
					if(value && 
          value.type === 'image/jpeg' ||
          value.type === 'image/png' ||
          value.type === 'image/gif'){
						return true;
					}
					return false; 
				}
			)
			.test(
				'fileSize',
				'File size should not exceed 5MB',
				(value) =>{ 
					if(value && value.size <= 5 * 1024 * 1024){
						return true;
					} // Adjust file size limit as needed
					return false;
				}
			),
		otherwise: (schema) => schema.notRequired()
	}),
	uploadedProfile: yup.string(),
	title: yup.string().required('Please select Title'),
	firstName: yup.string().required('Please enter First Name').matches(/^[A-Za-z ]+$/, 'First Name must contain only letters'),
	middleName: yup.string()
		.test('middle-name-validation', 'Middle Name must contain only letters', function(value) {
			const middleNameRegex = /^[A-Za-z ]*$/;
			if (value && !middleNameRegex.test(value)) {

				return false; // Validation failed
			}
			return true;
  
		}),
	lastName: yup.string().required('Please enter Last Name').matches(/^[A-Za-z ]+$/, 'Last Name must contain only letters'),
	dob:yup.string().required('Please enter DOB'),
	gender:yup.string().required('Please select Gender'),
	bloodGroup:yup.string(),
	enrollmentNo: yup.string().required('Please enter Enrollment No'),
	
	email:yup.string().required('Please enter Email').matches(
		/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
		'Invalid Email Format'
	),
	phoneNumber:yup.string()
		.test('invalid-validation', 'Invalid Phone Number', function(value) {
			if (value && !isValidPhoneNumber(value)) {
				return false; // Validation failed
			}
			return true;
		}).nullable(),
	doj:yup.string().required('Please enter DOJ'),
	pincode: yup.string()
		.test('pincode-validation', 'Pincode must contain only numbers', function(value) {
			const pincodeRegex = /^\d+$/;
			if (value && !pincodeRegex.test(value)) {
				return false; // Validation failed
			}
			return true;
		})
		.test('pincode-digit-validation', 'Pincode must be 6 digits', function(value) {
			if (value && value.length !== 6) {
				return false; // Validation failed
			}
			return true;
		}),
	residentalPincode: yup.string()
		.test('pincode-validation', 'Pincode must contain only numbers', function(value) {
			const pincodeRegex = /^\d+$/;
			if (value && !pincodeRegex.test(value)) {
				return false; // Validation failed
			}
			return true;
		})
		.test('pincode-digit-validation', 'Pincode must be 6 digits', function(value) {
			if (value && value.length !== 6) {
				return false; // Validation failed
			}
			return true;
		})


});

const countryChanged = (country) =>{
	console.log(country);
	dialCode.value = country.dialCode;
}

const onSubmit = async (data) => {
	
	saveMember.value = true;
  
	console.log('data',data);
	console.log("form.value",form.value.upload_profile);
	console.log("profileImageId.value",profileImageId.value);

	let payload = {
		"profilePictureId":profileImageId.value,
		"title": data.title,
		"firstName":capitalizeString(data.firstName.trim()),
		"middleName": capitalizeString(data.middleName.trim()),
		"lastName": capitalizeString(data.lastName.trim()),
		"dateOfBirth": data.dob,
		"gender": data.gender,
		"bloodGroup": data.bloodGroup,
		"email": data.email.trim().toLowerCase(),
		"otherInformation": {
			"dateOfJoining": data.doj,
			"enrollmentNo": capitalizeString(data.enrollmentNo),
			"typeOfAdvocate": data.typeOfAdvocate,
			"certificateNo": data.certificateNo,
			"vakalat": data.vakalat,
			"fax": data.fax,
			"isFirmRegistered": data.firmRegistration
		},
		"businessDetails": [
			{
				"address": {
					"addressLine1": capitalizeString(data.addressLineOne),
					"addressLine2": capitalizeString(data.addressLineTwo),
					"postalCode": data.pincode.trim(),
					"district": data.district
				}
			}
		],
		"residentialAddress": {
			"addressLine1": capitalizeString(data.residentalAddressLineOne),
			"addressLine2": capitalizeString(data.residentalAddressLineTwo),
			"postalCode": data.residentalPincode.trim(),
			"district": data.residentalDistrict
		}
					
	}

	if(data.phoneNumber!=''){
		payload.phone = {
			"countryCode": '+'+dialCode.value,
			"number":data.phoneNumber.replace(data.phoneNumber.split(' ')[0],'').replace(/\D/g, '')
		}
	}else{
		payload.phone={
			"countryCode": '',
			"number":''
		}
	}


	const method = memberId.value ? 'put' : 'post';
	const url = memberId.value ? `${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}` : `${process.env.VUE_APP_SERVICE_URL}/saveMember`;
	try {
		const response =  await createMember(method,url,payload);
 
		if(response.status === 200){
			saveMember.value = false;
			if(memberId.value){
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Updated Successfully',
					color:'success',
					timeout:2000});
				router.push({name:'members'});
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Added Successfully',
					color:'success',
					timeout:2000});
				resetForm()
				// dob.value = '';
				profileImageId.value = '';
				imageUrl.value = '';
				document.querySelector("#upload_profile").value='';
			}
				
		}

	}catch (error) {
    //console.log(error)
		if (error.response) {
			if(error.response.data.message){
				saveMember.value = false;
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
 
	
};



const resetForm = ()=>{
	console.log('form.value',form.value);
  
	form.value.resetForm() 
	setTimeout(()=>{
		form.value.setFieldError('dob', '') 
	  form.value.setFieldError('doj', '') 
	},100)
}


const editMember = (id)=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/users/${id}`,{
			headers: {
				'Authorization': `Bearer ${authToken.value}`,
			}})
		.then((response) => {
			console.log("edit response",response);
			if(response.status === 200){
				let getMemberDetails = response.data.data[0];
        
				// // profile image
				if(getMemberDetails?.profilePictureId){
					profileImageId.value =  getMemberDetails?.profilePictureId;
					loadImage(getMemberDetails?.profilePictureId)
					
			   }else{
					imageUrl.value = '';
					profileImageId.value = '';
				 }
				// // profile image
        
				title.value = `${getMemberDetails.title?getMemberDetails.title :''}`;
				firstName.value =  `${getMemberDetails.firstName?getMemberDetails.firstName :''}`; 
				middleName.value =  `${getMemberDetails.middleName?getMemberDetails.middleName :''}`; 
				lastName.value =  `${getMemberDetails.lastName?getMemberDetails.lastName :''}`; 
				dob.value =  `${getMemberDetails.dateOfBirth?getMemberDetails.dateOfBirth :''}`;
				gender.value =  `${getMemberDetails.gender?getMemberDetails.gender :''}`; 
				bloodGroup.value = getMemberDetails.bloodGroup?getMemberDetails.bloodGroup :''; 
				email.value = getMemberDetails.email?getMemberDetails.email :''; 
				setTimeout(()=>{
					phoneNumber.value = getMemberDetails.phone?`${getMemberDetails.phone.countryCode} ${getMemberDetails.phone.number}`:''; 
				},1000)
				addressLineOne.value = getMemberDetails?.businessDetails?.[0]?.address?.addressLine1 || ''; 
				addressLineTwo.value = getMemberDetails?.businessDetails?.[0]?.address?.addressLine2 || ''; 
				district.value = getMemberDetails?.businessDetails?.[0]?.address?.district || '';
				pincode.value = getMemberDetails?.businessDetails?.[0]?.address?.postalCode || ''; 
				residentalAddressLineOne.value = getMemberDetails?.residentialAddress?.addressLine1 || ''; 
				residentalAddressLineTwo.value = getMemberDetails?.residentialAddress?.addressLine2 || ''; 
				residentalDistrict.value = getMemberDetails?.residentialAddress?.district || '';
				residentalPincode.value = getMemberDetails?.residentialAddress?.postalCode || '';
				enrollmentNo.value = getMemberDetails?.otherInformation?.enrollmentNo  || '';
				typeOfAdvocate.value = getMemberDetails?.otherInformation?.typeOfAdvocate || ''; 
				fax.value = getMemberDetails?.otherInformation?.fax || ''; 
				certificateNo.value = getMemberDetails?.otherInformation?.certificateNo || ''; 
				vakalat.value = getMemberDetails?.otherInformation?.vakalat || ''; 
				firmRegistration.value = getMemberDetails?.otherInformation?.isFirmRegistered || ''; 
				doj.value = getMemberDetails?.otherInformation?.dateOfJoining  || '';
        

      
			}
		})
		.catch((error)=>{
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}
							
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.data.message,
						color:'danger',
						timeout:2000});

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
}



onMounted(()=>{
  
	if(memberId.value){
		formHeading.value="Edit Member";
		editMember(memberId.value);
	}else{
		formHeading.value="Add Member";
	}


})




let base64String = ref('');
const profileUpload = (e) =>{
	console.log("e",e.target.files[0]);

	
	let fileType = e.target.files[0].type;


	console.log("e",e.target.files[0].type);

	if(	 fileType === 'image/jpeg' ||
	     fileType === 'image/png' ||
	     fileType === 'image/gif'){
		document.querySelector(".profile-error").innerHTML="";
		if(e.target.files[0].size <= 5 * 1024 * 1024){
			  
			let reader = new FileReader();
			reader.onload = function () {
				base64String.value = reader.result.replace("data:", "")
					.replace(/^.+,/, "");
 
				console.log(base64String.value);
				uploadBase64(base64String.value)
			}
			reader.readAsDataURL(e.target.files[0]);

		}else{
			// document.querySelector(".profile-error").innerHTML="File size should not exceed 5MB"
		}
   
	}else{
		//  document.querySelector(".profile-error").innerHTML="Invalid file extension. Please select a JPEG or PNG file"

	}

}


const uploadBase64 = async(baseData)=>{
	imageUrl.value = '';
	loader.value = true;
	try {
		const response  = await uploadImage(`/profileUpload`,{image:baseData});
   
		if(response.status === 200){
			console.log(" file upload response",response.data.data);
			imageUrl.value = 'data:image/jpeg;base64,'+baseData;
			uploadedProfile.value = 'data:image/jpeg;base64,'+baseData;
			profileImageId.value =  response?.data?.data?._id;
        
			if(memberId.value){
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Profile image updated successfully',
					color:'success',
					timeout:2000});
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Profile image added successfully',
					color:'success',
					timeout:2000});
			}
        
			loader.value = false;

		}
	} catch (error) {
		loader.value = false;
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});	

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}


}

const loadImage = (id)=>{

	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/profileUpload/${id}`,
			{headers:{
				'Authorization': `Bearer ${authToken.value}`,
			}})
		.then((response) => {
      
			if(response.status === 200){
				console.log(" Image decoded response",response.data.data[0].image);
				if(response.data.data[0].image){
					imageUrl.value = 'data:image/jpeg;base64,'+response.data.data[0].image;
					uploadedProfile.value = 'data:image/jpeg;base64,'+response.data.data[0].image;
				}else{
					imageUrl.value = require(`@/assets/images/avatar.svg`);
				}
      
			}
		})
		.catch((error)=>{
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}
							

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});

}

const removeProfile = async()=>{
	loader.value = true;

	try {
		const response = await deleteProfile(`/profileUpload/${profileImageId.value}`)
		if(response.status === 200){
			console.log("DELTE PROFIEL ",response.data.data);
				
			profileImageId.value = '';
			uploadedProfile.value = '';
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deleted Successfully',
				color:'success',
				timeout:2000});
			document.querySelector("#upload_profile").value='';
			imageUrl.value = '';
			loader.value = false;
			console.log("RMprofileImageId.value",profileImageId.value);
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}   

}

 
function cancelUpdate(){
	router.push({name:'members'});
}


</script>




<style scoped>
.form-card{box-shadow: 0px 10px 15px 0px #7C7C7C33;
  border: 1px solid #dddddd;border-radius: 12px;width: 97%;margin: 25px auto 0;
background-color:white;padding: 17px;}
.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input + span ,select + span ,.input-group + span,.vue-tel-input + span{color:red;text-align: left;font-size: 12px;}
 .profile-container ~ span{color: red;text-align: left;font-size: 12px;}
 .vue-tel-input{height: 38px;border-radius: 5px;}
.form-col-half,.form-col-full{display: flex;flex-direction: column;flex:1;margin-bottom: 14px;}
.form-col-half{margin-right:30px;}
.form-col-half:last-child{margin-right:0px;}
.form-profile{flex-basis: 20%;margin-right: 15px;}
.basic-information{flex-basis:80%;}
.profile-container{position: relative; width:170px;height: 170px;border-radius: 50%;margin: auto;
    display: block;}
.profile-image {position: relative; width:170px;height: 170px;border-radius: 50%;margin:auto;overflow: hidden;}
.profile-image > img{position: absolute;width: 170px;
    height: 170px;object-fit: cover;}
.profile-image > label{ position: absolute;
    bottom: -14px;
    background-color: #5a5a5a73;
    width: 170px;
    height: 80px;
    margin-bottom: 0;
    cursor: pointer;
    text-align: center;}
    .profile-image > label .fa-camera{color: white;
    font-size: 29px;
    margin-top: 13px;}

    .progress-bar{position: relative;left:0;}
    .progress-bar::after{position: absolute;content:'';left:0;right:0;top: 0; display:block;margin:auto;width:0%;height:5px;background-color: white;    animation: increaseWidth 2s infinite }
    @keyframes increaseWidth {
  0% {
    width:0%;
    opacity:1;
  }
  100% {
    width:100%;
    opacity:0;/* Change this value to adjust the final width */
  }
}
     .remove-profile{
     
      top: 0;
    right: 27px;
    position: absolute;
    z-index: 1000;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #FF0000;
    color: white;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
     }
.title{flex-basis: 14%!important;flex:unset;}
.doj-col{flex-basis: 18%!important;flex:unset;}
/* .form-col-half{flex-basis: 50%;} */
/* .form-col-full{flex-basis: 100%;} */
.form-col-half input,.form-col-full input,.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
    height: 38px;padding: 4px 13px;width:100%;text-transform: capitalize;}
 .form-col-half input:focus,.form-col-full input:focus,.form-col-half select:focus{outline: unset;}   
 select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:gray;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
.form-actions{display: flex;justify-content: flex-end;margin-top: 12px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}
:deep() .iti__selected-flag{background-color:unset;}

/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
/* .input-group input{border:unset;outline: unset;} */
/* .flat-pickr{width:100%!important;}  */
/* .flatpickr-input{width: 30%;background-color: #007CBA;} */
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}


button:disabled {
	opacity: 0.5; 
	cursor: not-allowed; 
	}

@media only screen and (max-width: 768px) {
  .form-row{flex-direction: column;}
  .form-col-half, .form-col-full{margin-right: unset;}
}
</style>

