import { createStore } from 'vuex'
import router from '@/router';

const authentication = {
	namespaced: true,
	state: () => ({
		token:localStorage.getItem('token') || '',
		name:localStorage.getItem('name') || '',
		id:localStorage.getItem('id') || ''}),
		
	mutations: { 
		setName(state,name){
			state.name = name;
			localStorage.setItem('name',name); 
		},
		setToken (state, token) {
			state.token = token;
			localStorage.setItem('token',token) },
		setID (state, id) {
			state.id = id;
			localStorage.setItem('id',id) },
		clearToken (state) {
			state.token = '';
			localStorage.removeItem('token');},
		logout(state,payload) {
			localStorage.removeItem('token');
			localStorage.removeItem('name');
			state.token = '';
			state.name = '';
			setTimeout(()=>{
				router.push('/')
			},payload)
			
		},	
	},
	actions: {  
		// login(context, token) {
		// 	context.commit('setToken', token);},
		// logout(context) {
		// 	context.commit('clearToken');}
	},
	getters: {  }
}

const members = {
	// namespaced: true,
	state: () => ({
		allMembers:0,
		activeMembers:0,
		inactiveMembers:0,
	    serverItems:[],
		totalItems:0,
		loading:false}),
	mutations: {  },
	actions: {  },
	getters: {  }
}

const events = {
	state: () => ({
		activeEvent:0,
		eventHistory:0,
		allEvent:0}),
	mutations: {  },
	actions: {  },
	getters: {  }
}

const gallery = {
	state: () => ({
		tableRefresh:false,
		allGallery:0,
		published:0,
		unPublished:0
	}),
	mutations: {  },
	actions: {  },
	getters: {  }
}

const manageVakalat = {
	state: () => ({
		tableRefresh:false
	}),
	mutations: {  },
	actions: {  },
	getters: {  }
}

const officeBearer = {
	state: () => ({
		tableRefresh:false
	}),
	mutations: {  },
	actions: {  },
	getters: {  }
}

const snackbar = {
	namespaced: true,
	state: () => ({
		show:false,
		timeout:2000,
		color:'success',
	    message:''}),
	mutations: { 
		showSnackbar(state,payload){
			state.show = payload.show;
			state.message = payload.message;
			state.color = payload.color;
			state.timeout = payload.timeout;
			
		}
	 },
	actions: {  },
	getters: {  }
}


const venue = {
	state: () => ({
		venueEditMode:false,
		addHallPopup:false,
		tableRefresh:false}),
	mutations: {  },
	actions: {  },
	getters: {  }
}
const store =  createStore({
	
	modules:{
		authentication:authentication,
		members:members,
		events:events,
		venue:venue,
		snackbar:snackbar,
		gallery:gallery,
		manageVakalat:manageVakalat,
		officeBearer:officeBearer
	}
});

export default store;
