<template>
  <v-dialog 
    v-model="createVakalatDialog"
    width="400"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
          
            
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;">
            {{ formHeading }}
          </h3>
          <div class="form-card">
            <Form
              ref="form"
              :validation-schema="schema"
              @submit="onSubmit"
            >
              <div class="form-row">
                <div class="form-col-half">
                  <label for="vakalat_date">Vakalat Date<span>*</span></label>
                  <div class="input-group">
                    <Field
                      id="vakalat_date"
                      v-slot="{field}"
                      v-model="vakalatDate"
                      name="vakalat_date"
                    >
                      <flat-pickr
                              
                        v-bind="field"
                        v-model="field.value"
                        :config="vdConfig"
                        class="date-form-control"
                        placeholder="Vakalat Date"
                        name="vakalat_date"
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          colorpicker="colorpickere"
                          data-toggle
                        >
                          <fa icon="calendar" />
                          <span
                            aria-hidden="true"
                            class="sr-only"
                          >Toggle</span>
                        </button>
                      </div>
                    </Field>
                  </div>
                  <ErrorMessage name="vakalat_date" />
                  <!-- <Calendar /> -->
                </div>
              </div>   


              <div class="form-row">
                <div class="form-col-full">
                  <label for="vakalat_count">Vakalat Count<span>*</span> </label>
                  <Field
                    v-model="vakalatCount"
                    name="vakalat_count"
                    type="text"
                    placeholder="Vakalat Count"
                  />
                  <ErrorMessage name="vakalat_count" /> 
                  <span />
                </div>
              </div>
  
               
  
  
              
  
  
              <div class="form-row">
                <div class="form-col-full">
                  <div class="form-actions">
                    <button
                      type="button"
                      @click="resetForm"
                    >
                      Reset
                    </button>
                    <button 
                      :disabled="saveVakalat?true:false"
                      type="submit"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script setup>
import { useStore} from 'vuex';
import {ref,reactive,inject,watch } from 'vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import {createVakalat} from '@/service/helper-service.js';
  




const store = useStore(); 
const formHeading = ref('Vakalat')
  
  

const createVakalatDialog =  ref(false);
const memberId =  ref('');
const vakalatCount = ref();
const vakalatDate  = ref('');
const openVakalatDialog = inject('openVakalatDialog');
const saveVakalat = ref(false);

  
watch(openVakalatDialog, (newValue) => {
	console.log("newValue",newValue.status);
	createVakalatDialog.value = newValue.status
});

watch(openVakalatDialog, (newValue) => {
	console.log("IDDDD",newValue.id);
	memberId.value = newValue.id
});
  
  
  
  
  
    
  
    
  
const closeDialog = ()=>{
	openVakalatDialog.status =false;
	vakalatCount.value = '';
}
  
const form  = ref();




  
let vdConfig = reactive({
	wrap: true, // set wrap to true only when using 'input-group'
	altInput: true,
	altFormat: "j-M-Y",
	dateFormat: "Y-m-d",
	disableMobile: true,
	maxDate:new Date()
});
  


  
const schema = yup.object({
	vakalat_date:yup.string().required('Please select Date'),
	vakalat_count:yup.string().required('Please enter Count')
		.test('type-validation', 'Count should only consist of numeric digits', function(value) {
			const countRegex = /^\d+$/;
			if (value && !countRegex.test(value)) {
				return false; // Validation failed
			}
			return true;
		})
		.test('number-validation', 'Invalid Number', function(value) {
			if (value && value <= 0) {
				return false; // Validation failed
			}
			return true;
		})
});
  
  
  
  
  
const onSubmit = async(data) => {

	// albumId
	saveVakalat.value = true;
	const payload = {"vakalat": [{"date":vakalatDate.value,"count":+vakalatCount.value}]}
	try {
		const response = await createVakalat(`/users/${memberId.value}`,payload)
		if(response.status === 200){
        
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Updated Successfully',
				color:'success',
				timeout:2000});
			store.state.manageVakalat.tableRefresh = true;
			saveVakalat.value = false;
			closeDialog();
			form.value.resetForm();
		}
	} catch (error) {
		saveVakalat.value = false;
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}


};
  
  
  
const resetForm = ()=>{
	form.value.resetForm()  
	setTimeout(()=>{
		form.value.setFieldError('vakalat_date', '') 
	},50)
}
  

  
</script>
  
  <style scoped>
  /* add even dialog form */
  .add-event-dialog {position: relative!important;border-radius: 8px!important;}
  .add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
  .v-card-text h3{display: flex;align-items: center;}
  
   /* form design    */
  
  .form-row{display: flex;}
  label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
  label span{color:red;}
  input[type='text'] + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
  .form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
  .form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
  .form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
      height: 39px;padding: 4px 13px;width: 100%;text-transform: capitalize;}
  .form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
   .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
  .form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
  .form-actions button{background-color: #007CBA;
      color: white;
      border-radius: 7px;
      padding: 6px 28px;
      margin-left: 18px;}
  .error-msg{text-align: left;
      color: red;
      font-size: 12px;}
  button:disabled {
    opacity: 0.5; 
    cursor: not-allowed; 
	}   
  
  /* date picker */
  .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
  :deep() .form-col-half  .date-form-control{
    width: 100%;height: 37px;padding: 12px;}
  
   :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
    
    .input-group-append {
    display: flex;
    align-items: center; /* Aligns buttons vertically */
    /* Add additional styles as needed */
  }
  .input-group-append button{margin-right: 7px;}
  .input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
  .radio-group{display: flex;}
  .radio-input-wrap{display: flex;margin-right: 12px;}
  .radio-input-wrap input{margin-right: 12px;}
  .arr-btns-group{display: flex;
      align-items: self-end;
      height: 100%;}
  .arr-btns{background-color: #007CBA;
      color: white;
      width: 39px;
      height: 37px;
      border-radius: 5px;
      margin-bottom: 3px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;}
  
      .dropzone__box {
      
      align-items: center;
      border: 2px dashed #C5C5C5;
      border-radius: 8px;
      padding: 17px;
      min-height: 250px;
      background-color: #F6F6F6;
  }
      :deep() .upload-icon{position:relative;margin-top: 42px;}
      :deep() .upload-icon::after{
       width: 97px;
      content: '';
      height: 97px;
      background-image: url(../../assets/images/file-upload.png);
      background-size: contain;
      display: flex;
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -75%);}  
      
      :deep() .filepond--drop-label{min-height: 17.75em;border:2px dashed #C5C5C5;border-radius: 9px;}
      .v-dialog > .v-overlay__content > .v-card > .v-card-text{padding: 16px 8px 10px;}
  </style>
  