<template>
  <div class="bba-bg" />
  <div class="bg-overlay">
    <div class="form-card">
      <div class="logo">
        <img
          src="../assets/images/bba-logo.png"
          alt="logo"
        >
        <h1>Login</h1>
      </div>
        
      <Form
        ref="form"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="input-group">
          <Field
            v-model="userName"
            placeholder="Email"
            name="userName"
            type="text"
          />
        </div>
        <ErrorMessage name="userName" /> 
      
        <div class="input-group">
          <Field
            v-model="password"
            placeholder="Password"
            name="password"
            :type="showPassword ? 'text' : 'password'"
          />
          <div
            class="toggle"
            @click="togglePassword"
          >
            <fa :icon="showPassword ? 'eye' : 'eye-slash'" />
          </div>
        </div>
        <ErrorMessage name="password" /> 

        <button type="submit">
          <fa
            v-show="spinner===true"
            icon="spinner"
            spin
          />  Submit
        </button>    
      </Form>
    </div> 
  </div>
</template>


<script setup>
// @ is an alias to /src
import { useRoute,useRouter } from 'vue-router';
import {ref,onMounted} from 'vue'
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import { useStore } from 'vuex';
const store = useStore(); 


const router = useRouter();

const form =ref(null);  
const userName = ref('');
const password = ref('');

const showPassword = ref(false);


const text= ref('');
const spinner = ref(false);

const schema = yup.object({
	userName:yup.string().required('Please enter User Name'),
	password: yup.string('').required('Please enter Password')
});

const togglePassword = ()=>{
	showPassword.value = !showPassword.value;
}

const onSubmit = (data) => {
	console.log('data',data);

	login(data.userName,data.password)



};

const login = (name,password)=>{
	spinner.value = true;
	// let testToken = '1234';
	// store.authentication.commit('storeToken',testToken);

	axios
		.post(`${process.env.VUE_APP_SERVICE_URL}/login`,
			{"userName":name,
				"password":password},
		)
		.then((response) => {
			if(response.status === 200){
				console.log(response);
				console.log(response?.data?.data?.jwt);
				if(response?.data?.data?.jwt){
          
					spinner.value = false;
					store.commit('authentication/setToken', response?.data?.data?.jwt);
					store.commit('authentication/setName', response?.data?.data?.userData.userName);
					store.commit('authentication/setID', response?.data?.data?.userData?._id);
         
				}
          

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Logged in Successfully',
					color:'success',
					timeout:2000});


				form.value.resetForm();
			    router.push({ path: 'login' });
			}
		})
		.catch((error)=>{
			spinner.value = false;

			if (error.response) {
				if(error.response.data.message){
					
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.data.message,
						color:'danger',
						timeout:2000});
				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				text.value = error.message; 
			}
		});
}

onMounted(()=>{
	// store.commit('snackbar/showSnackbar',{
	// 				show:true,
	// 				message:'Logged in Successfully',
	// 				color:'success',
	// 				timeout:222000});
})
</script>

<style scoped>
  input:focus {
    outline: none; /* Remove the default blue outline */
    box-shadow: none; /* Remove any default box shadow */
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #e5e5e5 inset; /* Change the color here */
}
input:-moz-autofill {
  box-shadow: 0 0 0px 1000px #e5e5e5 inset; /* Change the color here */
}

   .bba-bg{
    position:absolute;
    background-image: url(../assets/images/login-bg.jpg);
    background-size: cover;

    background-repeat: no-repeat;
    width:100%;
    height:100vh;}
    .bg-overlay{

       position: absolute;
       position: absolute;
    background: #fffffe49;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    backdrop-filter: blur(3px);
    display:flex;
    justify-content: center;
    align-items: center;
    }
    .form-card{
        box-shadow: 0px 10px 15px 0px #7C7C7C33;
    border: 1px solid #dddddd;
    border-radius: 12px;
    margin: 25px auto 0;
    background-color: #ffffff45;
    padding: 17px;
    width: 350px;
    backdrop-filter: blur(10px);
    }
    .form-card h1{color:#007cba;text-align:center;font-size:20px;}
    .logo img{width: 115px;
    margin: auto;
    display: block;}
    .input-group{
        display: flex;
        border: 1px solid #B2B2B2;
    border-radius: 5px;
    height: 38px;
    padding: 4px 13px;
    width: 100%;
    margin-top: 19px;
    }
    .form-card input{width:100%;}
    .form-card input:focus{border:unset;outline:unset;}

    .form-card button{background-color: #007cba;
    border-radius: 5px;
    height: 40px;
    padding: 4px 13px;
    width: 100%;
    margin-top: 19px;
    color: white;}
    .form-card span{font-size:14px;color:red;}
    .form-card .toggle{cursor: pointer;}

    @media only screen and (max-width: 600px) {
      .form-card{max-width:90%;}
}
</style>


