<template>
  <div style="position: fixed;margin:auto;display: block;width: 100%;">
    <AdminPanel>
      <v-container>
        <div class="card-container">
          <div
            class="card"
            :class="{active:selectedInfoTab ==='all'}"
            @click="selectInfoCard('all')"
          >
            <div class="card-group">
              <fa icon="image" />
          
              <div class="count">
                {{ allGallery }}
              </div>
            </div>
            <div class="card-name">
              All Album
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='published'}"
            @click="selectInfoCard('published')"
          >
            <div class="card-group">
              <fa icon="check" />
              <div class="count">
                {{ publishedGallery }}
              </div>
            </div>
            <div class="card-name">
              Published Album
            </div>
          </div>
 
          <div
            class="card"
            :class="{active:selectedInfoTab ==='unPublished'}"
            @click="selectInfoCard('unPublished')"
          >
            <div class="card-group">
              <fa icon="undo" />
              <div class="count">
                {{ unPublishedGallery }}
              </div>
            </div>
            <div class="card-name">
              Unpublished Album
            </div>
          </div>
        </div> 
        <div>
          <AlbumList
            v-if="selectedInfoTab ==='all'"
            :type="'all'"
          />
          <AlbumList
            v-if="selectedInfoTab ==='published'"
            :type="'published'"
          />
          <AlbumList
            v-if="selectedInfoTab ==='unPublished'"
            :type="'unPublished'"
          />
        </div>
      </v-container>
    </AdminPanel>
  </div>
</template>


<script setup>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import AlbumList from '@/components/gallery/AlbumList.vue'

import {ref,onMounted,computed} from 'vue'
import {useStore} from 'vuex';
import axios from 'axios';


const store = useStore(); 
const selectedInfoTab = ref('all');


const allGallery = computed(()=>{
	return store.state.gallery.allGallery
})

const publishedGallery = computed(()=>{
	return store.state.gallery.published
})

const unPublishedGallery = computed(()=>{
	return store.state.gallery.unPublished
})

	


onMounted(()=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/count/gallery`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {

        
			let galleryCounts = response?.data?.data; 
			console.log("galleryCounts",galleryCounts);
        
			store.state.gallery.allGallery = galleryCounts?.allGallery;
			store.state.gallery.published = galleryCounts?.published;
			store.state.gallery.unPublished = galleryCounts?.unPublished;

		})
})
		
	
const selectInfoCard = (tabName)=>{
	selectedInfoTab.value = tabName;
}


</script>

<style scoped>
  .card-container{display: flex;justify-content:flex-start;margin-top: -5px;}
   .card{border-radius: 6px;
    border: 1px solid #d3d2d2;
    background-color: #f7f6f6;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    flex-basis: 220px;
    margin-left: 13px;
    cursor: pointer;
    }
    .card:nth-child(1){margin-left: unset;}
  .card:hover,.card.active{background-color: #007CBA;color:#fff;transition: ease-in-out .8s;}
  .card-group{display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;}
 
.card-name{font-weight: 700;margin-top: 12px;font-size: 14px;text-align: left;}
.card .svg-inline--fa{padding: 8px;border-radius: 50%;font-size: 21px;}
.card:nth-child(1) .svg-inline--fa{color: #007CBA;background-color: #cbcade;width: 22px;}
.card:nth-child(2) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;width: 22px;}
.card:nth-child(1) img{
    min-width: 37px;
    height: 37px;
  }
  .card:nth-child(3) img{
    min-width: 37px;
    height: 37px;
  }


.card:nth-child(3) .svg-inline--fa{color: #3632D4;background-color: #cbcade;}
.card:nth-child(4) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.card:nth-child(5) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.count{font-weight: 800;}

@media only screen and (max-width: 600px){
		.card-container{flex-direction: column;}
    .card{
    margin-left: unset;
    flex-basis: unset;
    margin-bottom: 12px;
    }
}
</style>


