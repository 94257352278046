import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import LoginView from '../views/LoginView.vue'
import MembersView from '../views/MembersView.vue'
import AddMembersView from '../views/AddMembersView.vue'
import EditMembersView from '../views/EditMembersView.vue'
import GalleryView from '../views/GalleryView.vue'
import CreateGalleryView from '../views/CreateGalleryView.vue'
import AddGalleryView from '../views/AddGalleryView.vue'
import BroadcastView from '../views/BroadcastView.vue'
import CreateBroadcastView from '../views/CreateBroadcastView.vue'
import EditBroadcastView from '../views/EditBroadcastView.vue'
import ManageVakalatView from '../views/ManageVakalatView.vue'
import ContactUsView from '../views/ContactUsView.vue'
import ManageOfficeBearersView from '../views/ManageOfficeBearersView.vue'

import NotFoundView from '../views/NotFoundView.vue'
// import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'

const routes = [
	{
		path: '/',
		name: 'login',
		component: LoginView,
		meta:{
			title:'Boothapandy Bar Association -  Login',
			auth:false
		}
	},
	{
		path: '/members',
		name: 'members',
		component: MembersView,
		meta:{
			title:'Boothapandy Bar Association -  Members',
			auth:true
		}
	},
	{
		path: '/add-members',
		name: 'add-members',
		component:AddMembersView,
		meta:{
			title:'Boothapandy Bar Association - Add Members',
			auth:true
		}
	},
	{
		path: '/edit-members/:id',
		name: 'edit-members',
		component: EditMembersView,
		meta:{
			title:'Boothapandy Bar Association -  Edit Members',
			auth:true
		}
	},
	{
		path: '/gallery',
		name: 'gallery',
		component:GalleryView,
		meta:{
			title:'Boothapandy Bar Association -  Gallery',
			auth:true
		}
	},
	{
		path: '/broadcast',
		name: 'broadcast',
		component:BroadcastView,
		meta:{
			title:'Boothapandy Bar Association -  Broadcast',
			auth:true
		}
	},
	{
		path: '/create-broadcast',
		name: 'create broadcast',
		component:CreateBroadcastView,
		meta:{
			title:'Boothapandy Bar Association - Create Broadcast',
			auth:true
		}
	},
	{
		path: '/edit-broadcast/:id',
		name: 'edit broadcast',
		component:EditBroadcastView,
		meta:{
			title:'Boothapandy Bar Association - Edit Broadcast',
			auth:true
		}
	},
	{
		path: '/create-gallery/:id',
		name: 'create gallery',
		component:CreateGalleryView,
		meta:{
			title:'Boothapandy Bar Association - Create Gallery',
			auth:true
		}
	},
	{
		path: '/add-gallery',
		name: 'add gallery',
		component:AddGalleryView,
		meta:{
			title:'Boothapandy Bar Association - Add Gallery',
			auth:true
		}
	},
	{
		path: '/manage-office-bearers',
		name: 'manage office bearers',
		component:ManageOfficeBearersView,
		meta:{
			title:'Boothapandy Bar Association -  Manage Office Bearers',
			auth:true
		}
	},
	{
		path: '/manage-vakalat',
		name: 'manage vakalat',
		component:ManageVakalatView,
		meta:{
			title:'Boothapandy Bar Association -  Manage Vakalat',
			auth:true
		}
	},
	{
		path: '/contact-us',
		name: 'Contact Us',
		component:ContactUsView,
		meta:{
			title:'Boothapandy Bar Association -  Contact Us',
		}
	},
	// {
	// 	path: '/privacy-policy',
	// 	name: 'privacy-policy',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: PrivacyPolicyView,
	// 	meta:{
	// 		title:'Boothapandy Bar Association -  Privacy Policy',
	// 		auth:false
	// 	}
	// },
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found/',
		component: NotFoundView,
		meta:{
			title:'Boothapandy Bar Association -  Not Found',
			auth:false
		}
	},
	{
		path: '/event',
		name: 'event',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/EventView.vue')
	},
	{
		path: '/speakers',
		name: 'speakers',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/SpeakersView.vue')
	},
	{
		path: '/test',
		name: 'test',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/TestView.vue')
	},
	{
		path: '/test-gallery',
		name: 'test-gallery',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/TestGalleryView.vue')
	},
	{
		path: '/test-file',
		name: 'test-file',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/TestFileView.vue')
	},
	{
		path: '/test-service',
		name: 'test-service',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/TestServiceView.vue')
	}

	
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to,from,next)=>{
//    console.log('to',to);
//    console.log('from',from);
//    console.log('next',next());
   
	if('auth' in to.meta && to.meta.auth && !store.state.authentication.token){
		document.title = `${to.meta.title}`;
		next('/');
	}else if('auth' in to.meta && !to.meta.auth && store.state.authentication.token){
		document.title = `${to.meta.title}`;
		next('/members');
	}else{
		document.title = `${to.meta.title}`;
		next();
	}
	// document.title = `${to.meta.title}`;
	// next();
})

export default router
