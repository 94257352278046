<template>
  <AdminPanel>
    <v-container>
      <div>
        <CreateBroadcast />
      </div>
    </v-container>
  </AdminPanel>
</template>


<script>
// @ is an alias to /src

import AdminPanel from '@/components/ui/AdminPanel.vue'
import CreateBroadcast from '@/components/broadcast/CreateBroadcast.vue'

export default {
	name: 'AddMembersView',
	components: {
		AdminPanel,
		CreateBroadcast,
	},
	data(){
		return {
			selectedFormTab:'AddMember',
			// memberId:this.$route.params.id
		}},
	methods:{
		selectFormTab(tabName){
			this.selectedFormTab = tabName;
           
		}
	},
}
</script>

<style scoped>
.form_tab_container{display: flex;
    border-bottom: 1px solid #B2B2B2;
    height: 70px;
    justify-content: center;}
  .card{min-width: 250px;
     height: 70px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-right: 20px;
    color: #9D9D9D;}
  .card:last-child{ margin-right: unset;}
  .active{color:black;position: relative;}  
 .active::after{position: absolute;content: '';left: 0;bottom: 0; width:100%;height: 5px; background-color:#007CBA;}
 .card-name{
  text-align: center;
  font-weight: 700;
    font-size: 20px;
   }   

</style>


