<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />

  <v-snackbar
    v-model="store.state.snackbar.show"
    :timeout="store.state.snackbar.timeout"
    :color="store.state.snackbar.color"
  >
    {{ store.state.snackbar.message }}
  
    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="store.state.snackbar.show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script setup>
import {ref,onMounted} from 'vue'
import { useStore } from 'vuex';
const store = useStore(); 
</script>
<style>
body{margin:0px;font-family: 'Mulish', sans-serif;}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
}

nav {
  padding: 5px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.bg-danger{background-color: #FF0000!important;}
.v-snackbar__actions > .v-btn > .v-btn__content{color: white;}
.v-list-item--one-line:nth-child(6){display: none!important;}
/* table fixed */
.v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th{background-color: #65A4C4!important;color:#fff;}
.v-table__wrapper{position: relative ;overflow-y:auto;}
  .v-table__wrapper::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.v-table__wrapper::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}
.v-table__wrapper::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}

button{position: relative;}
button:after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.8s;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
button:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s
}
</style>
