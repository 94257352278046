<template>
  <div class="bba-bg" />
  <div class="bg-overlay">
    <div class="contact-info">
      <h1>Contact US</h1>
      <div class="logo">
        <img
          src="../assets/images/bba-logo.png"
          alt="logo"
        >
      </div>
    </div>
    <div class="form-card">
      <div class="logo">
        <!-- <img
          src="../assets/images/bba-logo.png"
          alt="logo"
        > -->
        <h2>Get in touch</h2>
      </div>
        
      <Form
        ref="form"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="form-row">
          <div class="form-col-half">
            <label for="firstName">First Name<span>*</span></label>
            <Field
              v-model="firstName"
              name="firstName"
              type="text"
              placeholder="First Name"
            />
            <ErrorMessage name="firstName" /> 
            <span />
          </div>

          <div class="form-col-half">
            <label for="lastName">Last Name<span>*</span></label>
            <Field
              v-model="lastName"
              name="lastName"
              type="text"
              placeholder="Last Name"
            />
            <ErrorMessage name="lastName" /> 
            <span />
          </div>
        </div>

        <div class="form-row">
          <div class="form-col-full">
            <label for="email">Email<span>*</span></label>
            <Field
              v-model="email"
              name="email"
              type="text"
              placeholder="Email"
            />
            <ErrorMessage name="email" /> 
            <span />
          </div>
        </div>

        <div class="form-row">
          <div class="form-col-full">
            <label for="subject">Subject<span>*</span></label>
            <Field
              v-model="subject"
              name="subject"
              type="text"
              placeholder="Subject"
            />
            <ErrorMessage name="subject" /> 
            <span />
          </div>
        </div>


        <div class="form-row">
          <div class="form-col-full">
            <label for="message">Message<span>*</span></label>
            <Field
              v-model="message"
              name="message"
              type="text"
              as="textarea"
              row="30"
              placeholder="message"
            />
            <ErrorMessage name="message" /> 
            <span />
          </div>
        </div>
      









        <button type="submit">
          <fa
            v-show="spinner===true"
            icon="spinner"
            spin
          />  Submit
        </button>    
      </Form>
    </div> 
  </div>
</template>


<script setup>
// @ is an alias to /src
import { useRoute,useRouter } from 'vue-router';
import {ref,onMounted} from 'vue'
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import { useStore } from 'vuex';
const store = useStore(); 


const router = useRouter();

const form =ref(null);  

const spinner = ref(false);

const schema = yup.object({
	firstName:yup.string().required('Please enter First Name'),
    lastName:yup.string().required('Please enter Last Name'),
    email:yup.string().required('Please enter Email').matches(
		/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
		'Invalid Email Format'
	),
    subject:yup.string().required('Please enter Subject'),
    message:yup.string().required('Please enter Message'),
});



const onSubmit = (data) => {
	console.log('data',data);

	// login(data.userName,data.password)



};



onMounted(()=>{
	// store.commit('snackbar/showSnackbar',{
	// 				show:true,
	// 				message:'Logged in Successfully',
	// 				color:'success',
	// 				timeout:222000});
})
</script>

<style scoped>
  input:focus {
    outline: none; /* Remove the default blue outline */
    box-shadow: none; /* Remove any default box shadow */
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #e5e5e5 inset; /* Change the color here */
}
input:-moz-autofill {
  box-shadow: 0 0 0px 1000px #e5e5e5 inset; /* Change the color here */
}

   .bba-bg{
    position:absolute;
    background-image: url(../assets/images/contact-us-bg.jpg);
    background-size: cover;
    transform: scaleX(-1);
    background-repeat: no-repeat;
    width:100%;
    height:100vh;}
    .bg-overlay{

       position: absolute;
       position: absolute;
    background: #fffffe49;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    backdrop-filter: blur(13px);
    display:flex;
    justify-content: space-around;
    align-items: center;
    }
    .contact-info h1{color: rgb(66, 66, 66);
    font-size: 35px;
    margin-bottom: 33px;
    }
    
    .form-card{
        box-shadow: 0px 10px 15px 0px #7C7C7C33;
    border: 1px solid #dddddd;
    border-radius: 12px;
    /* margin: 25px auto 0; */
    background-color: #ffffff45;
    padding: 17px;
    width: 550px;
    backdrop-filter: blur(20px);
    }
    .form-card h2{color:black;font-size:30px;margin-bottom: 12px;}
    .logo img{width: 130px;
    margin: auto;
    display: block;}

    .form-card button{background-color: #007cba;
    border-radius: 5px;
    height: 40px;
    padding: 4px 13px;
    width: 100%;
    margin-top: 19px;
    color: white;}
    /* .form-card span{font-size:14px;color:red;}
    .form-card .toggle{cursor: pointer;} */

    .form-row{display: flex;}
    label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
    label span{color:red;}
    input + span ,select + span ,textarea + span,.input-group + span,.vue-tel-input + span{color:red;text-align: left;font-size: 12px;}
    .profile-container ~ span{color: red;text-align: left;font-size: 12px;}
    .form-col-half,.form-col-full{display: flex;flex-direction: column;flex:1;margin-bottom: 14px;}
    .form-col-half{margin-right:30px;}
    .form-col-half:last-child{margin-right:0px;}
    .form-col-half input,.form-col-full input,.form-col-half select,.form-col-full select,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;
    height: 38px;padding: 4px 13px;width:100%;text-transform: capitalize;}
 .form-col-half input:focus,.form-col-full input:focus,.form-col-half select:focus,.form-col-full textarea{outline: unset;}   
 select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}

    @media only screen and (max-width:728px) {
      .form-card{max-width:90%;}
      .bg-overlay{flex-direction: column;min-height: 100%;}
      .contact-info h1{
    font-size: 30px;
    margin-bottom: 4px;
    margin-top: 12px;
}
.logo img{width:95px;}
.form-row{flex-direction: column;}
.form-col-half{margin-right: unset;}
}

</style>


