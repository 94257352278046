<template>
  <v-dialog 
    v-model="showSpeakerDialog"
    width="400"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;">
            {{ openOfficeBearerDialog.id!=''?'Edit Office Bearer':'Create Office Bearers' }} 
          </h3>
          <div class="form-card">
            <Form
              ref="form"
              :validation-schema="schema"
              @submit="onSubmit"
            >
              <div class="form-row">
                <div class="form-col-full">
                  <label for="role">Role</label>
                  <Field
                    id="role"
                    ref="selectDropdown"
                    v-model="role"
                    name="role"
                    as="select"
                    @change="handleSelectChange"
                  >
                    <option value="">
                      Select Role
                    </option>
                    <option
                      v-for="item in roleList"
                      :key="item._id"
                      :value="item._id"
                    >
                      {{ item.role }}
                    </option>
                  </Field>
                  <ErrorMessage name="role" />
                </div>
              </div>


              <div class="form-row">
                <div class="form-col-full">
                  <label for="members">Members</label>
                  <Field
                    v-model="members"
                    name="members"
                    type="text"
                    readonly
                    @click="showMemberDropdown"
                  />
                  <div
                    v-show="dropdownEnabled==true"
                    class="member-list-wrapper"
                  >
                    <div class="search-icon">
                      <fa icon="search" />
                      <input
                        id="search_member"
                        type="text"
                        placeholder="Search"
                        @keyup="searchMember"
                      >
                    </div>
                    <div class="member-list-dropdown">
                      <div
                        v-for="item in memberList"
                    
                        :key="item.id"
                        :ref="memberRefs"
                      >
                        <label
                        
                          :for="item?._id"
                          style="display: block;"
                          class="member-name"
                          :class="memberId==item?._id?'active':''"
                        ><input
                          :id="item?._id"
                          type="checkbox"
                          :data-id="item?._id"
                          :value="joinName(item?.firstName,item?.middleName,item?.lastName)"
                          @change="addMembers"
                        > {{ truncateString(joinName(item?.firstName,item?.middleName,item?.lastName),25) }}</label>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage name="members" />
                </div>
              </div>



              <div class="form-row">
                <div class="form-col-full">
                  <div class="form-actions">
                    <button
                      v-if="mode=='add'"
                      type="button"
                      @click="resetForm"
                    >
                      Reset
                    </button>
                    <button 
                      :disabled="saveOfficeBearer?true:false"
                      type="submit"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useStore} from 'vuex';
import {ref, inject, watch, onMounted} from 'vue'
import 'flatpickr/dist/flatpickr.css';
import { Form, Field, ErrorMessage} from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import { truncateString } from '@/helper/index.js';
import {getMemberRole, getAllMemberList, createOfficeBearer} from '@/service/helper-service.js';

const emit = defineEmits(['submit'])



let showSpeakerDialog =  ref(false);
const store = useStore(); 

let openOfficeBearerDialog = inject('openOfficeBearerDialog');
let memberId = ref('');
let mode = inject('mode');
const selectDropdown = ref(null);
const selectedOptionText = ref('');


console.log("Inject",openOfficeBearerDialog);


const form = ref(null);




const role = ref('');
const members = ref('');

const roleList = ref([])
const memberList = ref([]);
const saveOfficeBearer = ref(false);

watch(openOfficeBearerDialog, (newValue) => {
	console.log("newValue",newValue.status);
	showSpeakerDialog.value = newValue.status
});

watch(openOfficeBearerDialog, (newValue) => {
	console.log("newValue",newValue.id);
	memberId.value = newValue.id
});


watch(mode, (newValue) => {
	console.log("memberId",newValue);
	if(newValue=='edit'){
		editMember(memberId.value);
	}
});

const dropdownEnabled = ref(false);

const showMemberDropdown = ()=>{
	dropdownEnabled.value = !dropdownEnabled.value; 
}


const handleSelectChange = (e) => {
	console.log("selectDropdown.value",e.target.options[e.target.selectedIndex].textContent);
	selectedOptionText.value = e.target.options[e.target.selectedIndex].textContent;
};




  
onMounted(() => {

	loadRoles();
	loadMembers();
	
})


const loadRoles = async () =>{
	try {
		const response = await getMemberRole(`/role`);
		if(response.status === 200){
			roleList.value = response?.data?.data;
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}

}


const loadMembers = async() =>{
	try {
		const response = await getAllMemberList(`/all/users`);
		if(response.status === 200){
			console.log("Members response",response.data.data);
			memberList.value = response?.data?.data;
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}


	}
	
}








const closeDialog = ()=>{
	openOfficeBearerDialog.status =false;
	resetForm()
}





const schema = yup.object({
	// file :yup.string().required('Please upload profile '),
	role:yup.string().required('Please select Role'),
	members:yup.string().required('Please select Member')
});



const onSubmit = async(data) => {
	
	saveOfficeBearer.value = true;

	const bearerPayload = membersId.value.map(item => {
		return { "roleId": role.value,"role":selectedOptionText.value, "memberId": item, "isOfficeBearer":true };
	});

	try{
		const response = await createOfficeBearer(`/role/update`,bearerPayload)
		if(response.status === 200){
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Created Successfully',
				color:'success',
				timeout:2000});
			store.state.officeBearer.tableRefresh = true;
			saveOfficeBearer.value = false;
			closeDialog()
				
		}
	} catch (error) {
		saveOfficeBearer.value = false;
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}

	
	
}

const editMember = (id)=>{
	selectedMember.value= [];
	membersId.value = [];
	members.value = '';
	role.value = ''

	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/users/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log("edit response",response);
			if(response.status === 200){
				showSpeakerDialog.value = true;
				openOfficeBearerDialog.status =true;
				let getMemberDetails = response.data.data[0];
        
				role.value =  getMemberDetails?.roleId || '';
				selectedOptionText.value = roleList.value.filter(item=> item._id == role.value)[0]?.role;

				selectedMember.value.push(joinName(getMemberDetails.firstName?getMemberDetails.firstName :'',
					getMemberDetails.middleName?getMemberDetails.middleName :'',
					getMemberDetails.lastName?getMemberDetails.lastName :''))

				members.value = selectedMember.value.join(", ");
				membersId.value.push(memberId.value);
				console.log("selectedMember.value",selectedMember.value);

				
			}
		})
}

const resetForm = ()=>{
	form.value.resetForm();
	mode.value = 'add';
	selectedMember.value = [];
	openOfficeBearerDialog.id = '';
	membersId.value = [];
	document.querySelectorAll(".member-name").forEach((item)=>{
		item.classList.remove('active');
	})
	document.querySelector("#search_member").value = "";
}

const selectedMember = ref([]);
const membersId = ref([]);

const memberRefs = ref([])

const addMembers = (e)=>{
	const index =selectedMember.value.indexOf(e.target.value);

	if (index > -1) { 
		selectedMember.value.splice(index, 1); 
		
	}else{
		selectedMember.value.push(e.target.value);
		// e.target.classList.add('active');
		
	}

	members.value = selectedMember.value.join(", ");

	//for members id
	const idIndex = membersId.value.indexOf(e.target.getAttribute('data-id'));
	if (idIndex > -1) { 
		membersId.value.splice(idIndex, 1); 
		e.target.parentNode.classList.remove('active')
	}else{
		membersId.value.push(e.target.getAttribute('data-id'));
		e.target.parentNode.classList.add('active')
	}
	console.log("membersId",membersId.value)
}

const searchMember = (e)=>{
	console.log("e",e.target.value);
	console.log(memberRefs.value);

	const memberName = document.querySelectorAll(".member-name"); // Replace with your own selector
	for (let elem of memberName) {
		if (elem.textContent.toLowerCase().indexOf(e.target.value) != -1) {
			elem.style.display = 'block';
		} else {
			elem.style.display = 'none';
		}
	}
	
}

const joinName = (first,middle,last) => {
	let fullName = '';
	if(first){
		fullName = first;
	}

	if(middle){
		fullName+=' '+middle;
	}

	if(last){
		fullName+=' '+last;
	}
    
	return fullName;
}

</script>

<style scoped>
.v-container{padding: 0;}
.pointer{cursor: pointer;}
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */
.profile-upload{margin-bottom: 22px;}
.profile_image img{display: block;
    margin: 0 auto 15px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;}


.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='file'] + span, input[type='text'] ~ span ,select + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
.title{flex-basis: 30%;margin-right: 18px;}
.firstName{flex-basis: 70%;}

.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select,.title select,.firstName input{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
    
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}

/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}

select,input[name="members"]{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
button:disabled {
    opacity: 0.5; 
    cursor: not-allowed; 
} 
.member-list-wrapper{border: 1px solid #b2b2b2;
    border-radius: 5px;margin-top: 12px;}
.member-list-dropdown{position: relative;height:180px;overflow-y:auto;}
  .member-list-dropdown::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.member-list-dropdown::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}
.member-list-dropdown::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
.member-list-dropdown label{cursor: pointer !important;border-top: 1px solid #b4b4b4;
    padding: 8px 12px;margin-bottom: 0;}
.member-list-dropdown input{display:none;}
#search_member{border:unset;}
.search-icon{display: flex;align-items: center;background-color: #f5f4f4;border-radius: 3px 3px 0 0;}
.search-icon .fa-magnifying-glass{margin-left: 12px;color: #b2b2b2;}
label.active{background-color:#ecf2f6;transition: .2s ease-in-out;}
</style>
