<template>
  <DetailDrawer
    :data="memberView.data"
    :full-name="memberView.fullName"
    @activate-member-confirmation="activateMemberConfirmation"
    @deactivate-member-confirmation="deactivateMemberConfirmation"
    @send-credentials="sendCredentials"
  />
  <div class="tab-heading-and-action">
    <h3>{{ capitalizeString(type) }} Members </h3>
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
          @input="searchMember"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
      
      <fa
        icon="filter"
        class="filter-btn"
        @click="openFilter"
      />
         
      <div
        v-if="showFilter === true"
        class="filtercard"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="closeFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div
            class="reset-btn pointer"
            @click="resetFilter"
          >
            RESET
          </div>
        </div>
         
        
        <select
          id="gender"
          v-model="selectedGender"
          name="gender"
        >
          <option value="">
            Gender
          </option>
          <option value="Male">
            Male
          </option>
          <option value="Female">
            Female
          </option>
          <option value="Other">
            Other
          </option>
        </select>
      </div>
    </div>
  </div>
   
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    v-bind="refresh"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.first_name`]="{ item }">
      <v-tooltip
        v-if="item.first_name!='' && item.first_name.length > 8 "
        :text="item.first_name"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(item.first_name,10) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.first_name }}
      </div>
    </template> 

    <template #[`item.middle_name`]="{ item }">
      <v-tooltip
        v-if="item.middle_name!='' && item.middle_name.length > 8 "
        :text="item.middle_name"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(item.middle_name,10) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.middle_name }}
      </div>
    </template>  
	
    <template #[`item.last_name`]="{ item }">
      <v-tooltip
        v-if="item.last_name!='' && item.last_name.length > 8 "
        :text="item.last_name"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(item.last_name,10) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.last_name }}
      </div>
    </template>  

    <template #[`item.email`]="{ item }">
      <v-tooltip :text="item.email">
        <template #activator="{ props }">
          <div
            v-if="item.email!=''"
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(item.email,10) }}
          </div>
        
          <div v-else>
            -
          </div>
        </template>
      </v-tooltip>
    </template>  

    <template #[`item.status`]="{ item }">
      <div :class="item.status">
        {{ item.status }}
      </div>
    </template>  
    <template #[`item.action`]="{ item }">
      <v-tooltip text="View">
        <template #activator="{ props }">
          <fa 
            v-bind="props"
            icon="eye"
            class="pointer"
            @click="viewMember(item.view)"
          />
        </template>
      </v-tooltip>

      <v-tooltip text="Edit">
        <template #activator="{ props }">
          <router-link :to="'/edit-members/'+item.action">
            <fa
              v-bind="props"
              icon="pen-to-square"
              class="pointer"
            />
          </router-link>
        </template>
      </v-tooltip>
    </template>
  </v-data-table-server>
  
  
   
  <div class="text-center">
    <!-- deactivate dialog -->
    <v-dialog 
      v-model="deactivateDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="deactivateDialog = false"
        >
          <fa icon="close" />
        </div>
          
            
        <v-card-text>
          <h3> <fa icon="trash-can" /> Are you sure?</h3>
          Are you sure you want to deactivate this member? This action 
          cannot be undone.
        </v-card-text>
       
        <v-card-actions class="delete-action">
          <button @click="deactivateDialog = false">
            Cancel
          </button>
          <button @click="deactivateReasonConfirmation">
            Deactivate
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- deactivate dialog -->
  
  
    <!-- deactivate reason dialog -->
    <v-dialog 
      v-model="deactivateReasonDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="deactivateReasonDialog = false;deactivateReason =''"
        >
          <fa icon="close" />
        </div>
          
            
        <v-card-text>
          <h3>Reason for Deactivate</h3>
        </v-card-text>
         
        <input
          v-model="deactivateReason"
          type="text"
        >
        <span class="err-msg">{{ errMessage }}</span> 
        <v-card-actions class="delete-action">
          <button @click="deactivateReasonDialog = false;deactivateReason =''">
            Cancel
          </button>
          <button 
            :disabled="deactivating?true:false"
            @click="deactivateMember"
          >
            Submit
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- deactivate reason dialog -->
  
  
    <!-- activate dialog -->
    <v-dialog 
      v-model="activateDialog"
      width="500"
    >
      <v-card class="delete-dialog activate-dialog">
        <div
          class="close-icon"
          @click="activateDialog = false"
        >
          <fa icon="close" />
        </div>
          
            
        <v-card-text>
          <h3> <fa icon="check" /> Are you sure?</h3>
          Are you sure you want to activate this member? This action cannot be undone. 
        </v-card-text>
       
        <v-card-actions class="delete-action ">
          <button @click="activateDialog = false">
            Cancel
          </button>
          <button
            class="activate-flat-button"
            :disabled="activating?true:false"
            @click="activateMember"
          >
            Activate
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- activate dialog -->
  </div>
</template>
  
  
  
  
  
<script setup>
import axios from 'axios';
import DetailDrawer  from '@/components/members/DetailDrawer.vue';
import {ref,reactive,provide,watch,onBeforeMount} from 'vue'
import { useStore } from 'vuex';
import { truncateString, formattedDate, memberActivity } from '@/helper/index.js';
import {getMemberList,activateUser,deactivateUser,generateCredentials} from '@/service/helper-service.js';
  
const store = useStore();  
const props = defineProps({
	type:{
		type:String,
		default:''
	}
}) 

let openMemberDetail = reactive({'status':false,'id':''});
let sendCredentialMail = reactive({'status':false});
provide('openMemberDetail', openMemberDetail)
provide('sendCredentialMail', sendCredentialMail)
    

const itemsPerPage = ref(5);
const offset = ref(0);
const headers =ref([
	{ title: 'First Name', value: 'first_name' },
	{ title: 'Middle Name', value: 'middle_name' },
	{ title: 'Last Name', value: 'last_name' },
	{ title: 'Email', value: 'email' },
	{ title: 'Phone', value: 'phone_number' },
	{ title: 'Date of Joining', value: 'date_of_joining' },
	{ title: 'Status', value: 'status' },
	{ title: 'Action', value: 'action' }
])

const serverItems = ref([]);
const loading = ref(true);
const refresh =ref(false);
const totalItems = ref(0);
const memberId = ref('');
const memberView = reactive({
	imageUrl:require(`@/assets/images/avatar.svg`),
	imageName:'',
	fullName:'',
	data:{}
})
const showFilter = ref(false);
const selectedAdvocate = ref('');
const selectedFirm = ref('');
const selectedGender = ref('');
const name = ref(''); 
const search = ref(''); 
const deactivateDialog =  ref(false);
const deactivateReasonDialog = ref(false);
const deactivateReason =ref('');
const activateDialog = ref(false);
const errMessage =ref('');
const viewDialog = ref(false);
const timeout =  ref(null);
const activating = ref(false);
const deactivating = ref(false);

const tableHeight = ref(0);
		

	
		


watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});

watch(selectedGender, () => {
	search.value = String(Date.now())
});


onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
})






	

const loadItems = async({ page, itemsPerPage, sortBy })=>{
	offset.value = (page - 1) * itemsPerPage
	loading.value = true

	try {
		const response = await getMemberList(`/users?user=member${props.type=='all'?'':props.type=='active'?'&active=yes':'&active=no'}&limit=${itemsPerPage}&offset=${offset.value}`,{
			value:name.value,
			gender: selectedGender.value});

		console.log('response.data',response.data);
		serverItems.value = [];
		totalItems.value = response.data.totalCount;
		loading.value = false;
            
		let memberData = response.data.data;
		memberData.forEach((item,index) => {
			let memberList = {'first_name': item?.firstName || '',
				'middle_name': item?.middleName || '',
				'last_name': item?.lastName || '',
				'email':item?.email || '',
				'phone_number':`${item?.phone?.countryCode ?? ''} ${item?.phone?.number ?? 'N/A'}`,
				'status':item.isActive?'Active':'Inactive',
				'business_category': item?.industry || '',
				'chapter': item?.state || '',
				'member_since':item.createdAt ?? formattedDate(item.createdAt),
				'action':item?._id,
			  'view':item}

			if(item?.otherInformation?.dateOfJoining){
				memberList.date_of_joining =formattedDate(item.otherInformation.dateOfJoining)
			}else{
				memberList.date_of_joining ='';
			}
			serverItems.value.push(memberList)
		});

	} catch (error) {
		console.error('MEMBER LIST:', error);
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
							
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
              
}

const viewMember = (data) => {
	console.log("data",data);
	memberView.data = data;
	if(data?.title){
		memberView.fullName = data?.title +'. ';
	}

	if(data?.firstName){
		memberView.fullName+= data?.firstName;
	}

	if(data?.middleName){
		memberView.fullName+=' '+data?.middleName;
	}

	if(data?.lastName){
		memberView.fullName+=' '+data?.lastName;
	}
	openMemberDetail.status = true;

	
}

const deactivateMemberConfirmation = (item) =>{
	memberId.value = item;
	deactivateDialog.value =true;
}

const deactivateReasonConfirmation = ()=>{
	deactivateDialog.value = false;
	deactivateReasonDialog.value = true;
}

const deactivateMember = async()=>{
	if(deactivateReason.value==''){
		errMessage.value = 'Please enter reason';
	}else{
		errMessage.value ='';
		deactivating.value = true;
		
		try{
			const response  = await deactivateUser(`/users/${memberId.value}`,
				{'isActive':false,'deleted':true,'reason':deactivateReason.value})
			console.log(" deactivate response",response);
			deactivating.value = false;
			if(response.status === 200){
				deactivateDialog.value=false;
				openMemberDetail.status = false;
            
				// loading = true
				search.value = String(Date.now())
						
				deactivateReasonDialog.value = false;
				deactivateReason.value = '';
           
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Deactivated Successfully',
					color:'success',
					timeout:2000});
							 
				setTimeout(() => {
					loading.value = false
					store.state.members.activeMembers-=1;
					store.state.members.inactiveMembers+=1;
				}, 2000)
							
			}
		} catch (error) {
			deactivating.value = false;
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		}
	}         
}


const activateMemberConfirmation = (item)=>{
	memberId.value = item;
	activateDialog.value=true;
}

const activateMember = async()=>{
	activating.value = true;
	try{
		const response = await activateUser(`/users/${memberId.value}`,
			{'isActive':true,'deleted':false})
		
		if(response.status === 200){
			activateDialog.value=false;
			openMemberDetail.status = false;
            
			// loading = true
			search.value = String(Date.now())
           
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Activated Successfully',
				color:'success',
				timeout:2000});
							 
			setTimeout(() => {
				loading.value = false
				store.state.members.activeMembers+=1;
				store.state.members.inactiveMembers-=1;
			}, 2000)

			activating.value = false;
							
		}

	} catch (error) {
		activating.value = false;
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
          
}

const sendCredentials = async()=>{
	sendCredentialMail.status = true;

	try{
		const response = await generateCredentials('/send/credential',{"userId":memberId.value})
	    if(response.status === 200){
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Login credentials sent successfully.',
				color:'success',
				timeout:2000});
									 
			setTimeout(() => {
				sendCredentialMail.status = false;
			}, 1000)
									
		}
	}catch (error) {
		sendCredentialMail.status = false;
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}

	
}

const openFilter = ()=>{
	showFilter.value = true;
}

const closeFilter = ()=>{
	showFilter.value = false;
}

const resetFilter = ()=>{
	selectedAdvocate.value ='';
	selectedFirm.value = '';
	selectedGender.value ='';
}



const loadImage = (id)=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/profileUpload/${id}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
				// 'Content-Type': 'multipart/form-data'
			}})
		.then((response) => {
				
			if(response.status === 200){
				console.log(" Image decoded response",response.data.data[0].image);
				if(response.data.data[0].image){
					memberView.imageUrl = 'data:image/jpeg;base64,'+response.data.data[0].image;
				}else{
					memberView.imageUrl = require(`@/assets/images/avatar.svg`);
				}
				
			}
		})
		.catch((error)=>{
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}
							

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
}


const closeView = ()=>{
	memberView.fullName = "";
	viewDialog.value = false;
}

function capitalizeString(inputString) {

	return inputString.split(' ').map(word => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}).join(' ');

}
   

  
  
</script>
  
  <style scoped>
  .tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
  .tab-heading-and-action h3{font-size: 18px;}
  .tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
  
  
  .search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
   .search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
   .search-container input{outline: unset;}
  
   .filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
  .filter-btn{position: relative;cursor: pointer;}
  .filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
  .filtercard select{border: 1px solid #B2B2B2;
      padding: 6px;
      margin-bottom: 12px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:#B2B2B2;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  :deep() .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
  
  .filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
  .reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;}
  .filter-close-btn{width: max-content;
      margin-left: auto;
      display: block;
     cursor: pointer;}
  
  .v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
  .v-table :deep() thead tr > th{width: 120px;}
  .v-table :deep() tbody tr > td{text-align: left;}
  .v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
  .v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
  .v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
  .v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
  .v-table :deep() tbody tr > td .fa-pen-to-square{color: #353535;margin-right: 5px;}
  .v-table :deep() tbody tr > td .fa-circle-xmark{color:#EC1B1B;}
  .v-table :deep() tbody tr > td:nth-child(1),
  .v-table :deep() tbody tr > td:nth-child(2),
  .v-table :deep() tbody tr > td:nth-child(3),
  .v-table :deep() tbody tr > td:nth-child(5),
  .v-table :deep() tbody tr > td:nth-child(6){min-width: 150px;}
  .v-table :deep() tbody tr > td:nth-child(7){
	min-width: 100px;
  }
  .v-data-table-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 4px;
    justify-content: center;
    position: absolute;
    bottom: 0;
    background: orange;
    width: 100%;
}
  .pointer{cursor: pointer;}
  
  .delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
  .delete-dialog input{ border: 1px solid gray;
      border-radius: 4px;
      height: 38px;
      margin: 12px 24px 0;
	  padding:12px;
	  outline: unset;
      background: white;}
   .delete-dialog .err-msg{margin-left: 24px;
      color: red;
      font-size: 14px;}   
  .delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
  .v-card-text h3{display: flex;align-items: center;}
  
  .v-card-text .fa-trash-can{color: #EC1B1B;
      background-color: #FEE4E2;
      padding: 9px;
      border-radius: 50%;
      font-size: 15px;
      margin-right: 12px;}
  .delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
  .delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
  .delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
  .delete-action button{padding: 6px 13px;
      border-radius: 9px;
      border: 1px solid;}
  
   .view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;z-index:1040;width:100%;height: 100vh;}
   .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
   .view-modal-action{display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 12px;}
.view-modal-action .fa-pen-to-square{cursor: pointer;margin-left: 12px;}
.view-modal-action img{width: 60px;height:60px;border-radius:50%; margin-left: 12px;object-fit: cover;}
  .view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
  .view-member-details::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
  .member-details-content{position:sticky;text-align: left;}
  .member-details-content h5{color: #858585;font-weight: 500;
      font-size: 16px;}
  .member-details-content h4{color:#696868;font-size: 18px;margin-bottom: 9px;}
  .member-details-content p{color: #242426;font-weight: 600;
      margin-bottom: 23px;}
   .member-details-content button{
      border: 1px solid #EC1B1B;
      color: #EC1B1B;
      padding: 8px 10px;
      display: block;
      margin: 0 auto 12px;
      border-radius: 5px;
      font-weight: 600;
      min-width: 200px;}  
    .Active,.Inactive{padding: 5px 6px;
      text-align: center;
      font-weight: 800;
      border-radius: 5px;
      border:1px solid #C2C2C2;}   
    .Active{background-color: #CFFFD7;} 
    .Inactive{background-color: #FFCFCF;}  
	.send-credentials{background-color: #007CBA;border:1px solid #007CBA!important;color: #fff !important;}
	button:disabled {
	opacity: 0.5; 
	cursor: not-allowed; 
	}
	.activate-button{border: 1px solid #8BC34A!important;
    color: #8BC34A!important;
	}

	.activate-flat-button{
		background-color: #8BC34A!important;color: white;border-color: #8BC34A!important;
	}
	
	.drawer-active{
      animation: fade-in 500ms forwards;
	}
	.v-card-text .fa-check{
    color: #8BC34A;
    background-color: #CFFFD7;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;
    }
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
	.drawer-inactive{
      animation: fade-out 500ms forwards;
	}
	@keyframes fade-out{
	0%{
	opacity: 1;
	right: 0px;
	}
	100%{
	opacity: 0;
	right: -320px;
	}
	}

	@media only screen and (max-width: 600px){
		.tab-heading-and-action{flex-direction: column;}
		.filter{margin-left: unset;}
	}
  </style>
  
  