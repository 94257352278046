<template>
  <tr>
    <td>
      <v-tooltip
        v-if="firstName!='' && firstName.length > 8 "
        :text="firstName"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(firstName,10) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ firstName }}
      </div>
    </td>
    <td>
      <v-tooltip
        v-if="middleName!='' && middleName.length > 8 "
        :text="middleName"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(middleName,10) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ middleName }}
      </div>
    </td>
    <td>
      <v-tooltip
        v-if="lastName!='' && lastName.length > 8"
        :text="lastName"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(lastName,10) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ lastName }}
      </div>
    </td>
    <td>{{ vakalatCount }}</td>
    <td>{{ lastUpdate }}</td>
    <td>
      <span class="inline-action">
        <v-tooltip text="View">
          <template #activator="{ props }">
            <fa
              icon="eye"
              class="pointer"
              v-bind="props"
              @click="$emit('clickViewDrawer',action)"
            />
          </template>
        </v-tooltip>
        
        <div
          class="add-vakalat pointer"
          @click="$emit('clickAddVakalat',action)"
        >
          <fa
            icon="add"
            class="pointer"
          />
          Add 
        </div>
      </span>
    </td>
  </tr>

</template>
<script setup>
import {ref,onBeforeMount} from 'vue'
import { useStore } from 'vuex';
import moment from 'moment';
import { truncateString } from '@/helper/index.js';
import {getVakalatCount} from '@/service/helper-service.js';

const store = useStore();
const vakalatCount = ref(0);

const emit = defineEmits(['clickViewDrawer','clickAddVakalat']);
const rowProps = defineProps({
	firstName:{
		type:String,
		default:''
	},
	lastName:{
		type:String,
		default:''
	},
	middleName:{
		type:String,
		default:''
	},
	totalCount:{
		type:Number,
		default:0
	},
	lastUpdate:{
		type:String,
		default:''
	},
	action:{
		type:String,
		default:''
	},
})

onBeforeMount(()=>{
  getCount(rowProps.action)
})


const getCount = async(id) =>{
	try{
		const response  = await getVakalatCount(`/vakalat?userId=${id}&fromDate=${vMinDate()}&toDate=${vMaxDate()}`)
		let getMemberDetails = response.data.data[0];
		vakalatCount.value = getMemberDetails?.vakalat?.length > 0?getMemberDetails?.vakalat.reduce((total, currentValue) => total + currentValue.count, 0) : 0;

	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}

	
}

const vMaxDate = ()=>{
	let currentYear = moment().year();
	let maxDate = moment().year(currentYear).month(10).date(30);
	return maxDate.format('YYYY-MM-DD');
}

const vMinDate = ()=>{
	let currentDate = moment();
	if(currentDate.month() + 1 == 12){
		let currentYear = moment().year();
		return moment(`${currentYear}-12-01`).format('YYYY-MM-DD');
	}else{
		let previousYear = currentDate.clone().subtract(1, 'year');
		return previousYear.month(11).date(1).format('YYYY-MM-DD');
	}
}


</script>

<style scoped>
.inline-action{display: flex;align-items:center;}
.add-vakalat{background-color: #007cba;
    color: #ffff;
    padding: 3px 9px;
    border-radius: 4px;
    margin-left: 8px;}
.pointer{cursor: pointer;}   
</style>