<template>
  <CreateOfficeBearers />
  <DetailDrawer
    :full-name="memberView.fullName"
    :role-name="memberView.roleName"
  />
  <div class="tab-heading-and-action">
    <div>
      <h3>Office Bearers </h3>
    </div> 
    
    <div class="filter">
      <div class="input-group">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
    
      <div
        class="add-speakers"
        @click="clickSpeakerDialog"
      >
        <fa icon="plus" /> Create Office Bearers
      </div>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.member`]="{ item }">
      <v-tooltip
        v-if="item.member!='' && item.member.length > 10 "
        :text="item.member"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString((item.member),28) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.member }}
      </div>
    </template> 

    <template #[`item.action`]="{ item }">
      <v-tooltip text="View">
        <template #activator="{ props }">
          <fa
            icon="eye"
            class="pointer"
            v-bind="props"
            @click="viewMember(item.view)"
          />
        </template>
      </v-tooltip>

      <v-tooltip text="Edit">
        <template #activator="{ props }">
          <fa
            icon="pen-to-square"
            class="pointer"
            v-bind="props"
            @click="editMember(item.action)"
          />
        </template>
      </v-tooltip>
    </template>
  </v-data-table-server>

</template>





<script setup>
import CreateOfficeBearers  from '@/components/office_bearers/CreateOfficeBearers.vue';
import DetailDrawer  from '@/components/office_bearers/DetailDrawer.vue';
import {ref,reactive,watch,provide,computed,onBeforeMount} from 'vue'
import { useStore } from 'vuex';
import { truncateString } from '@/helper/index.js';
import {getOfficeBearerList} from '@/service/helper-service.js';






const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'Role', value: 'role' },
	{ title: 'Member', value: 'member' },
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const mode =  ref('add');
const memberView = reactive({
	fullName:'',
	roleName:'',
})

const name= ref('');
const calories= ref('');
const search= ref('');
const timeout= ref(null);
const store = useStore();
const tableHeight = ref(0);



let openOfficeBearerDialog = reactive({'status':false,'id':''});
let openBearerDetail = reactive({'status':false,'id':''});

provide('openOfficeBearerDialog',openOfficeBearerDialog)
provide('openBearerDetail',openBearerDetail)
provide('mode',mode)
// let formSubmitted =  inject('formSubmitted');
const tableRefresh = computed(() => store.state.officeBearer.tableRefresh);

onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
})


watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
})

watch(calories, () => {
	search.value = String(Date.now())
})

watch(tableRefresh, (newValue) => {
	if(newValue === true){
		search.value = String(Date.now())
	}
})





const clickSpeakerDialog = () =>{
     
	openOfficeBearerDialog.status = true;
	console.log("openOfficeBearerDialog.value",openOfficeBearerDialog.status);

}


const loadItems  = async({ page, itemsPerPage, sortBy })=> {
	offset.value = (page - 1) * itemsPerPage
	console.log("offset",offset.value);
	loading.value = true

	try {
		const response = await getOfficeBearerList(`/users?user=officeBearer&limit=${itemsPerPage}&offset=${offset.value}`,{
			value:name.value});
		serverItems.value = []
		console.log('response.data.title',response.data);
		totalItems.value = response.data.totalCount;
		loading.value = false
		store.state.officeBearer.tableRefresh = false;
          
		let memberData = response.data.data;
		memberData.forEach((item,index) => {
			let fullName =  `${item.title?item.title+'.' :''} ${item.firstName?item.firstName :''} ${item.lastName?item.lastName :''}`;
			let viewData = {'roleName':item.role,'fullName':fullName}
			serverItems.value.push({role: item.role?item.role:'-',
				member: fullName,
				action:item._id,
			    view:viewData})
		});

	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
 
}





	

const viewMember = (data)=>{
	openBearerDetail.status = true;
	memberView.fullName = data?.fullName || 'N/A';
	memberView.roleName = data?.roleName || 'N/A';
}


const editMember = (id)=>{

	openOfficeBearerDialog.id = id;
	mode.value = 'edit';
	openOfficeBearerDialog.status = true;
}




const joinName = (first,middle,last) => {
	let fullName = '';
	if(first){
		fullName = first;
	}

	if(middle){
		fullName+=' '+middle;
	}

	if(last){
		fullName+=' '+last;
	}
    
	return fullName;
}
</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 22px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .add-speakers{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-speakers .fa-plus{margin-right: 8px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th:nth-child(1){width: 40%;}
.v-table :deep() thead tr > th:nth-child(2){width: 50%;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-circle-check{color: #007CBA;}


.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px);overflow-y:auto;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .drawer-active{
      animation: fade-in 500ms forwards;
	}
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
	.drawer-inactive{
      animation: fade-out 500ms forwards;
	}
	@keyframes fade-out{
	0%{
	opacity: 1;
	right: 0px;
	}
	100%{
	opacity: 0;
	right: -320px;
	}
	}

	@media only screen and (max-width: 600px){
		.tab-heading-and-action{flex-direction: column;}
		.filter{margin-left: unset;flex-direction: column;align-items: unset;}
    .add-speakers{margin-top: 12px;margin-left: unset;}
	}
</style>






