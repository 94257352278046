<template>
  <div
    v-if="viewDialog"
    class="view-dialog-overlay"
    @click.self="openBroadcastDetail.status = false"
  >
    <div class="view-member-modal drawer-active">
      <div class="view-modal-action">
        <fa
          icon="arrow-right-long"
          @click="openBroadcastDetail.status = false"
        />
        <v-tooltip :text="title">
          <template #activator="{ props }">
            <h3
              class="pointer"
              v-bind="props"
            >
              {{ truncatedString(title,20) }}
            </h3>
          </template>
        </v-tooltip>
      </div>

      <div class="view-member-details">
        <div class="member-details-content">
          <h5>Title</h5>
          <p>{{ title }}</p>
          <h5>Message</h5>
          <MarkdownRenderer :source="message" />
          <br>
          <h5>Gallery</h5>
          <p>{{ albumName }}</p>
          <h5>Attachments</h5>
          <div
            v-if="attachments.length > 0"
            class="attachments-container"
          >
            <div
            
              v-for="item in attachments"
              :key="item"
            >
              <v-tooltip
                v-if="item.fileName!='' && item.fileName.length > 8 "
                :text="item.fileName.split('/').pop().substring(36)"
              >
                <template #activator="{ props }">
                  <div
                    target="_blank"
                    href="#"
                    v-bind="props"
                  >
                    <img
                      v-if="getFileExtension(item.fileName)=='jpg' || getFileExtension(item.fileName)=='png'"
                      :src="'data:image/jpeg;base64,'+item.source"
                      class="attachments-thumbnail"
                    >
                  </div>
                </template>
              </v-tooltip>
              <v-tooltip
                v-if="item.fileName!='' && item.fileName.length > 8 "
                :text="item.fileName?.split('/').pop().substring(36)"
              >
                <template #activator="{ props }">
                  <div
                    v-if="getFileExtension(item.fileName)=='pdf' || getFileExtension(item.fileName)=='docx'"
                    target="_blank"
                    href="#"
                    v-bind="props"
                    class="attachments-thumbnail"
                  />
                </template>
              </v-tooltip>
            </div>
          </div>
          <div v-else>
            <p>N/A</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref,watch,inject} from 'vue'
import MarkdownRenderer from '@/components/ui/MarkdownRenderer.vue'
const viewDialog = ref(false);
const openBroadcastDetail = inject('openBroadcastDetail');





const props = defineProps({
	title: {
		type:String,
		default:''
	},
	message: {
		type:String,
		default:''
	},
	albumName: {
		type:String,
		default:''
	},
	attachments:{
		type:Array,
		default:() => []
	}
})




watch(openBroadcastDetail, (newValue) => {
	console.log("openBroadcastDetail.status",newValue.status);
	viewDialog.value = newValue.status;

});








function getFileExtension(filename) {
	// Find the last occurrence of the period (.)
	const lastDotIndex = filename.lastIndexOf('.');
	// If a period is found and it's not the last character, extract the substring after it
	if (lastDotIndex !== -1 && lastDotIndex < filename.length - 1) {
		return filename.substring(lastDotIndex + 1);
	}
	// If no period is found or it's the last character, return an empty string
	return '';
}






const truncatedString = (string,length) =>{
	let result = string.substring(0, length);
	// Add dots if the original string length is longer than 8 characters
	if (string.length > length) {
		result += "..";
	}
	return result;
}



</script>

<style scoped>
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:auto;}
  .view-member-details::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .inline-action{display: flex;align-items:center;}
    #vakalath-list {
  border-collapse: collapse;
  width: 100%;
}

#vakalath-list td, #vakalath-list th {
    padding: 12px 25px;
}

#vakalath-list tr:nth-child(odd){background-color: #e9f3f7;}

#vakalath-list th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #65A4C4;
  color: white;
}
#vakalath-list td:nth-child(2){text-align:right;
padding-right:48px;
}


.drawer-active{
      animation: fade-in 500ms forwards;
	}
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
	.drawer-inactive{
      animation: fade-out 500ms forwards;
	}
	@keyframes fade-out{
	0%{
	opacity: 1;
	right: 0px;
	}
	100%{
	opacity: 0;
	right: -320px;
	}
	}

  .attachments-container{width: 100%;
    display: flex;
    flex-wrap: wrap;
    }
  .attachments-thumbnail,a.attachments-thumbnail{display: block; width: 80px;height: 80px;margin-right: 12px; object-fit: cover;background-color: gainsboro;border-radius: 5px;margin-bottom: 12px;}
</style>