<template>
  <AddVakalat />
  <DetailDrawer
    :full-name="memberView.fullName"
    :total-count="memberView.totalCount"
    :list="memberView.list"
  />
  <div class="tab-heading-and-action">
    <div>
      <h3>Vakalat  </h3>
    </div> 
    
    <div class="filter">
      <div class="input-group">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #item="{ item }">
      <TableRow
        :key="item.action"
        :first-name="item.first_name"
        :middle-name="item.middle_name"
        :last-name="item.last_name"
        :total-count="0"
        :last-update="item.last_update"
        :action="item.action"
        @click-view-drawer="viewVakalat"
        @click-add-vakalat="addVakalat"
      />
    </template>

    <template #[`item.action`]="{ item }">
      <span class="inline-action">
        <v-tooltip text="View">
          <template #activator="{ props }">
            <fa
              icon="eye"
              class="pointer"
              v-bind="props"
              @click="viewVakalat(item.action)"
            />
          </template>
        </v-tooltip>
        
        <div
          class="add-vakalat pointer"
          @click="addVakalat(item.action)"
        >
          <fa
            icon="add"
            class="pointer"
          />
          Add 
        </div>
      </span>
    </template>
  </v-data-table-server>

  
  <!-- delete dialog -->
  <v-dialog 
    v-model="deleteDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3> <fa icon="trash-can" /> Are you sure?</h3>
        Are you sure you want to delete this speaker? This action 
        cannot be undone.
      </v-card-text>
     
      <v-card-actions class="delete-action">
        <button @click="deleteDialog = false">
          Cancel
        </button>
        <button @click="deleteSpeaker">
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete dialog -->

    
      
 


  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>





<script setup>
import AddVakalat  from '@/components/manage_vakalat/AddVakalat.vue';
import DetailDrawer  from '@/components/manage_vakalat/DetailDrawer.vue';
import TableRow  from '@/components/manage_vakalat/TableRow.vue';
import {ref,reactive,watch,provide,computed,onBeforeMount} from 'vue'
import { useStore } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import {getVakalatList} from '@/service/helper-service.js';





let openVakalatDialog = reactive({'status':false,'id':''});
let openHistoryDialog = reactive({'status':false,'id':''});
provide('openVakalatDialog',openVakalatDialog)
provide('openHistoryDialog',openHistoryDialog)

const store = useStore();
const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'First Name', value: 'first_name' },
	{ title: 'Middle Name', value: 'middle_name' },
	{ title: 'Last Name', value: 'last_name' },
	{ title: 'Total Count', value: 'total_count' },
	{ title: 'Last Update', value: 'last_update' },
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const memberId=ref('');
const mode =  ref('add');


const name= ref('');
const calories= ref('');
const search= ref('');
const snackbar=ref(false);
const deleteDialog=ref(false);
const text= ref('');
const timeout= ref(null);
const tableHeight = ref(0);


let openOfficeBearerDialog = reactive({'status':false});
provide('openOfficeBearerDialog',openOfficeBearerDialog)
provide('memberId',memberId)
provide('mode',mode)
const tableRefresh = computed(() => store.state.manageVakalat.tableRefresh);

const memberView = reactive({
	fullName:'',
	totalCount:0,
	list:[]
})

onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
})

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
})

watch(calories, () => {
	search.value = String(Date.now())
})



watch(tableRefresh, (newValue) => {
	if(newValue === true){
		search.value = String(Date.now())
	}
})




const vMaxDate = ()=>{
	let currentYear = moment().year();
	let maxDate = moment().year(currentYear).month(10).date(30);
	return maxDate.format('YYYY-MM-DD');
}

const vMinDate = ()=>{
	let currentDate = moment();
	if(currentDate.month() + 1 == 12){
		let currentYear = moment().year();
		return moment(`${currentYear}-12-01`).format('YYYY-MM-DD');
	}else{
		let previousYear = currentDate.clone().subtract(1, 'year');
		return previousYear.month(11).date(1).format('YYYY-MM-DD');
	}
}
  



const loadItems  = async({ page, itemsPerPage, sortBy })=> {
	offset.value = (page - 1) * itemsPerPage
	console.log("offset",offset.value);
	loading.value = true

	try {
		const response = await getVakalatList(`/users?user=member&active=yes&limit=${itemsPerPage}&offset=${offset.value}`,{
			value:name.value});
		serverItems.value = []
		console.log('response.data.title',response.data);
		// serverItems = response.data.data;
		totalItems.value = response.data.totalCount;
		loading.value = false
		store.state.manageVakalat.tableRefresh = false;
          
		let memberData = response.data.data;
		memberData.forEach((item,index) => {
			let vakalatList = {
				first_name:item.firstName?item.firstName :'',
				middle_name:item.middleName?item.middleName :'',
				last_name:item.lastName?item.lastName :'',
				// total_count: item.totalVakalatCount?item.totalVakalatCount :'',
				last_update: item.updatedAt?formattedDate(item.updatedAt) :'',
				action:item._id}
			if(item?.vakalat){
				vakalatList.total_count = item?.vakalat.reduce((total, currentValue) => total + currentValue.count, 0)
			}else{
				vakalatList.total_count = 0;
			}	
                
			serverItems.value.push(vakalatList)
		});
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
      
	

        
}





	
const viewVakalat = (id) =>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/vakalat?userId=${id}&fromDate=${vMinDate()}&toDate=${vMaxDate()}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log("response",response);
			openHistoryDialog.status = true;
	        openHistoryDialog.id = id;
			let getMemberDetails = response.data.data[0];
			console.log("getMemberDetails",getMemberDetails);
			// memberView.fullName = `${getMemberDetails.title?getMemberDetails.title :''} ${getMemberDetails.firstName?getMemberDetails.firstName :''} ${getMemberDetails.lastName?getMemberDetails.lastName :''}`;
			if(getMemberDetails?.title){
				memberView.fullName = getMemberDetails?.title +'. ';
			}

			if(getMemberDetails?.firstName){
				memberView.fullName+= getMemberDetails?.firstName;
			}

			if(getMemberDetails?.middleName){
				memberView.fullName+=' '+getMemberDetails?.middleName;
			}

			if(getMemberDetails?.lastName){
				memberView.fullName+=' '+getMemberDetails?.lastName;
			}
         
			
			memberView.list = getMemberDetails?.vakalat || [];
              
			memberView.totalCount = getMemberDetails?.vakalat?.length > 0?getMemberDetails?.vakalat.reduce((total, currentValue) => total + currentValue.count, 0) : 0;

		
					
		})
		.catch((error)=>{
			if (error.response) {
				if(error.response.data.message){
					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}

					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.data.message,
						color:'danger',
						timeout:2000});
							

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
}





const addVakalat = (id)=>{
	openVakalatDialog.status = true;
	openVakalatDialog.id = id;
}





const formattedDate = (d)=>{
	return moment(d).format('DD-MMM-YYYY');
}





</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 22px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .add-speakers{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-speakers .fa-plus{margin-right: 8px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th:nth-child(4) .v-data-table-header__content {justify-content: flex-end;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-circle-check{color: #007CBA;}
.v-table :deep() tbody tr > td:nth-child(1),
.v-table :deep() tbody tr > td:nth-child(2),
.v-table :deep() tbody tr > td:nth-child(3),
.v-table :deep() tbody tr > td:nth-child(5),
.v-table :deep() tbody tr > td:nth-child(6){min-width: 150px;}
.v-table :deep() tbody tr > td:nth-child(4){

text-align: right;
}

 


.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .inline-action{display: flex;align-items:center;}
.add-vakalat{background-color: #007cba;
    color: #ffff;
    padding: 3px 9px;
    border-radius: 4px;
    margin-left: 8px;}

	

	@media only screen and (max-width: 600px){
		.tab-heading-and-action{flex-direction: column;}
		.filter{margin-left: unset;}
	}

    @media only screen and (max-width: 1336px) {
      .v-table :deep() thead tr > th:nth-child(4){min-width: 107px !important;}
      .v-table :deep() tbody tr > td:nth-child(4){min-width: 107px !important;}
}

   
</style>






