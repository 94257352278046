<template>
  <div v-html="markdown.render(source)" />
</template>

<script setup>
import MarkdownIt from "markdown-it";
const markdown = new MarkdownIt('commonmark');



const props = defineProps({
	source: {
		type: String,
		default: ""
	}
})

console.log("markdown.render(source)",markdown.render(props['source']));
</script>