<template>
  <v-dialog 
    v-model="imagePreviewDialog"
    width="800"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3 style="margin-bottom:15px;" />
        <div class="form-card">
          <div class="image-preview">
            <img
              :src="imageUrl"
            >
            <div
              v-if="caption!=''"
              class="caption-overlay"
            >
              {{ caption }}
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {ref,inject,watch } from 'vue'

const imageUrl = ref(require(`@/assets/images/thumbnail.png`));

const imagePreviewDialog = ref(false);
const caption = ref('');

const openPreviewDialog = inject('openPreviewDialog');
const previewImageData = inject('previewImageData');





watch(openPreviewDialog, (newValue) => {
	imagePreviewDialog.value = newValue.status
});

watch(previewImageData, (newValue) => {
	imageUrl.value = newValue.url
});

watch(previewImageData, (newValue) => {
	caption.value = newValue.caption
});


  

const closeDialog = ()=>{
	openPreviewDialog.status =false;
}



</script>

<style scoped>
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 11px;
    top: 6px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */

.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='text'] + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}

/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}
.arr-btns-group{display: flex;
    align-items: self-end;
    height: 100%;}
.arr-btns{background-color: #007CBA;
    color: white;
    width: 39px;
    height: 37px;
    border-radius: 5px;
    margin-bottom: 3px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;}

    .dropzone__box {
    
    align-items: center;
    border: 2px dashed #C5C5C5;
    border-radius: 8px;
    padding: 17px;
    min-height: 250px;
    background-color: #F6F6F6;
}
    :deep() .upload-icon{position:relative;margin-top: 42px;}
    :deep() .upload-icon::after{
     width: 97px;
    content: '';
    height: 97px;
    background-image: url(../../assets/images/file-upload.png);
    background-size: contain;
    display: flex;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -75%);}  
	
	:deep() .filepond--drop-label{min-height: 17.75em;border:2px dashed #C5C5C5;border-radius: 9px;}
    .image-preview{position:relative;background-color: #F6F6F6;border-radius:12px;}
    .image-preview img{border-radius: 8px;
    width: 100%;
    max-height: 450px;
    min-height: 100%;
    -o-object-fit: cover;
    object-fit: contain;
    margin-bottom: -5px;}
    .caption-overlay{
    background-color: #007cbacf;
    color: #ffff;
    position: absolute;
    width: 100%;
    padding: 16px;
    bottom: 0px;
    font-weight: 700;
    backdrop-filter: blur(2px);
    border-radius:0 0 12px 12px;
    }
</style>
